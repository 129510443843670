import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import api from '../../services/api';

/**
 * Componente para exibir o formulário de busca
 */
const SearchForm = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('all');
  const [results, setResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  // Verifica se há parâmetros de busca na URL quando o componente é montado
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('q');
    const typeParam = searchParams.get('type') || 'all';
    
    if (queryParam) {
      setSearchTerm(queryParam);
      setSearchType(typeParam);
      performSearch(queryParam, typeParam);
    }
  }, [location.search]);

  const performSearch = async (term, type) => {
    if (!term.trim()) {
      return;
    }
    
    setIsSearching(true);
    setError(null);
    
    try {
      const response = await api.get('/search/', {
        params: {
          q: term,
          type: type !== 'all' ? type : undefined
        }
      });
      
      setResults(response.data);
    } catch (err) {
      setError('Erro ao realizar a busca. Tente novamente.');
      console.error('Erro na busca:', err);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    performSearch(searchTerm, searchType);
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSearch} className="mb-8">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-grow">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar músicas, álbuns, artistas..."
              className="w-full px-4 py-3 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            />
          </div>
          <div className="flex gap-2">
            <select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              className="px-4 py-3 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="all">Todos</option>
              <option value="songs">Músicas</option>
              <option value="albums">Álbuns</option>
              <option value="artists">Artistas</option>
            </select>
            <button
              type="submit"
              disabled={isSearching}
              className="px-6 py-3 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 transition-colors"
            >
              {isSearching ? 'Buscando...' : 'Buscar'}
            </button>
          </div>
        </div>
      </form>

      {error && (
        <div className="bg-red-100 dark:bg-red-900 border border-red-400 text-red-700 dark:text-red-200 px-4 py-3 rounded relative mb-6" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {results && (
        <div className="search-results">
          {/* Resultados da busca seriam exibidos aqui */}
          {Object.keys(results).length === 0 ? (
            <div className="bg-gray-100 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 text-gray-700 dark:text-gray-300 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">Nenhum resultado encontrado para "{searchTerm}".</span>
            </div>
          ) : (
            <div className="space-y-8">
              {/* Resultados de Artistas */}
              {results.artists && results.artists.length > 0 && (
                <div>
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Artistas</h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {results.artists.map(artist => (
                      <div key={artist.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
                        <div className="p-4">
                          <div className="flex items-center mb-3">
                            {artist.profile_image ? (
                              <img
                                src={artist.profile_image}
                                alt={artist.username}
                                className="w-12 h-12 rounded-full object-cover mr-3"
                              />
                            ) : (
                              <div className="w-12 h-12 rounded-full bg-gray-300 dark:bg-dark-600 mr-3 flex items-center justify-center">
                                <span className="text-xl text-gray-600 dark:text-gray-400">
                                  {artist.username.charAt(0).toUpperCase()}
                                </span>
                              </div>
                            )}
                            <div>
                              <h3 className="font-bold text-gray-800 dark:text-white">{artist.username}</h3>
                              <p className="text-sm text-gray-600 dark:text-gray-400">
                                {artist.song_count} músicas • {artist.album_count} álbuns
                              </p>
                            </div>
                          </div>
                          <Link
                            to={artist.urlprofile || `/artists/${artist.id}`}
                            className="block text-center py-2 px-4 bg-primary-600 hover:bg-primary-700 text-white rounded-md transition-colors"
                          >
                            Ver perfil
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Resultados de Álbuns */}
              {results.albums && results.albums.length > 0 && (
                <div>
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Álbuns</h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {results.albums.map(album => (
                      <div key={album.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
                        {album.cover_image ? (
                          <img
                            src={album.cover_image}
                            alt={album.title}
                            className="w-full h-48 object-cover"
                          />
                        ) : (
                          <div className="w-full h-48 bg-gray-300 dark:bg-dark-600 flex items-center justify-center">
                            <span className="text-gray-600 dark:text-gray-400">Sem capa</span>
                          </div>
                        )}
                        <div className="p-4">
                          <h3 className="font-bold text-gray-800 dark:text-white mb-1">{album.title}</h3>
                          <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">{album.artist_name}</p>
                          <p className="text-xs text-gray-500 dark:text-gray-400 mb-3">{album.songs_count} músicas</p>
                          <Link
                            to={`/albums/${album.id}`}
                            className="block text-center py-2 px-4 bg-primary-600 hover:bg-primary-700 text-white rounded-md transition-colors"
                          >
                            Ver álbum
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Resultados de Músicas */}
              {results.songs && results.songs.length > 0 && (
                <div>
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Músicas</h2>
                  <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
                    {results.songs.map(song => (
                      <div
                        key={song.id}
                        className="border-b border-gray-200 dark:border-dark-600 last:border-b-0 hover:bg-gray-50 dark:hover:bg-dark-700"
                      >
                        <div className="p-4 flex items-center">
                          <div className="flex-grow">
                            <h3 className="font-bold text-gray-800 dark:text-white">{song.title}</h3>
                            <p className="text-sm text-gray-600 dark:text-gray-400">{song.artist_name}</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <button
                              className="p-2 rounded-full bg-primary-600 hover:bg-primary-700 text-white"
                              title="Reproduzir"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                              </svg>
                            </button>
                            <Link
                              to={`/songs/${song.id}`}
                              className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 dark:bg-dark-600 dark:hover:bg-dark-500 text-gray-700 dark:text-gray-300"
                              title="Ver detalhes"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchForm;