import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

const ProfilePage = () => {
  const { currentUser, updateProfile } = useAuth();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    full_name: '',
    bio: '',
    url_slug: '',
    facebook: '',
    twiter: '', // Mantendo o nome do campo como está no modelo
    instagram: '',
    site: ''
  });
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [profilePreview, setProfilePreview] = useState('');
  const [coverPreview, setCoverPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const profileInputRef = useRef(null);
  const coverInputRef = useRef(null);

  // Carregar dados do usuário quando o componente montar
  useEffect(() => {
    if (currentUser) {
      // Definimos os dados básicos que já temos
      setFormData({
        username: currentUser.username || '',
        email: currentUser.email || '',
        full_name: currentUser.full_name || '',
        bio: currentUser.bio || '',
        url_slug: currentUser.url_slug || '',
        facebook: currentUser.facebook || '',
        twiter: currentUser.twiter || '',
        instagram: currentUser.instagram || '',
        site: currentUser.site || ''
      });
      
      if (currentUser.profile_image) {
        setProfilePreview(currentUser.profile_image);
      }
      
      if (currentUser.cover_image) {
        setCoverPreview(currentUser.cover_image);
      }
    }
  }, [currentUser]);

  // Manipular mudanças nos campos do formulário
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Manipular seleção de imagem de perfil
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Manipular seleção de imagem de capa
  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Abrir seletor de arquivo para imagem de perfil
  const handleProfileImageClick = () => {
    profileInputRef.current.click();
  };

  // Abrir seletor de arquivo para imagem de capa
  const handleCoverImageClick = () => {
    coverInputRef.current.click();
  };

  // Enviar formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });

    try {
      // Criar FormData para enviar arquivos
      const formDataToSend = new FormData();
      
      // Adicionar campos de texto
      Object.keys(formData).forEach(key => {
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        }
      });
      
      // Adicionar imagens se foram selecionadas
      if (profileImage) {
        formDataToSend.append('profile_image', profileImage);
      }
      
      if (coverImage) {
        formDataToSend.append('cover_image', coverImage);
      }
      
      // Enviar para a API
      // Em uma implementação real, você teria um endpoint específico para atualizar o perfil
      // Simulando uma atualização bem-sucedida
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Atualizar o contexto de autenticação
      const success = await updateProfile(formData);
      
      if (success) {
        setMessage({
          type: 'success',
          text: 'Perfil atualizado com sucesso!'
        });
      } else {
        throw new Error('Falha ao atualizar perfil');
      }
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      setMessage({
        type: 'error',
        text: 'Erro ao atualizar perfil. Por favor, tente novamente.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Meu Perfil
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Gerencie suas informações pessoais e configurações de perfil.
        </p>
      </div>

      {message.text && (
        <div className={`mb-6 p-4 rounded-lg ${
          message.type === 'success' 
            ? 'bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300' 
            : 'bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300'
        }`}>
          {message.text}
        </div>
      )}

      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
        {/* Imagem de capa */}
        <div 
          className="relative h-48 bg-gray-200 dark:bg-dark-700 bg-cover bg-center cursor-pointer"
          style={{ backgroundImage: coverPreview ? `url(${coverPreview})` : 'none' }}
          onClick={handleCoverImageClick}
        >
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity">
            <div className="text-white text-center">
              <svg className="w-10 h-10 mx-auto" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
              </svg>
              <p className="mt-1">Alterar imagem de capa</p>
            </div>
          </div>
          <input 
            type="file" 
            ref={coverInputRef}
            className="hidden" 
            accept="image/*" 
            onChange={handleCoverImageChange}
          />
        </div>

        {/* Conteúdo do perfil */}
        <div className="p-6">
          {/* Imagem de perfil */}
          <div className="flex flex-col sm:flex-row items-center mb-6">
            <div 
              className="relative w-32 h-32 rounded-full overflow-hidden bg-gray-200 dark:bg-dark-700 cursor-pointer mb-4 sm:mb-0 sm:mr-6"
              onClick={handleProfileImageClick}
            >
              {profilePreview ? (
                <img 
                  src={profilePreview} 
                  alt="Foto de perfil" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="flex items-center justify-center h-full bg-gray-300 dark:bg-dark-600">
                  <svg className="w-16 h-16 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
                  </svg>
                </div>
              )}
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity">
                <svg className="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
                </svg>
              </div>
              <input 
                type="file" 
                ref={profileInputRef}
                className="hidden" 
                accept="image/*" 
                onChange={handleProfileImageChange}
              />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                {formData.full_name || formData.username}
              </h2>
              <p className="text-gray-600 dark:text-gray-300">
                @{formData.username}
              </p>
              {currentUser?.is_artist && (
                <span className="inline-block mt-2 px-3 py-1 bg-primary-100 dark:bg-primary-900 text-primary-800 dark:text-primary-200 text-xs font-medium rounded-full">
                  Artista
                </span>
              )}
            </div>
          </div>

          {/* Formulário de perfil */}
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nome de usuário
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="full_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nome completo
                </label>
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                />
              </div>
              
              <div>
                <label htmlFor="url_slug" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  URL personalizada
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 dark:border-dark-600 bg-gray-50 dark:bg-dark-600 text-gray-500 dark:text-gray-400 text-sm">
                    baladag4.com.br/
                  </span>
                  <input
                    type="text"
                    id="url_slug"
                    name="url_slug"
                    value={formData.url_slug}
                    onChange={handleChange}
                    className="flex-1 px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-r-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                    placeholder="seu-nome"
                  />
                </div>
              </div>
            </div>
            
            <div className="mb-6">
              <label htmlFor="bio" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Biografia
              </label>
              <textarea
                id="bio"
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                rows="4"
                className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                placeholder="Conte um pouco sobre você..."
              ></textarea>
            </div>
            
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Redes Sociais</h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div>
                <label htmlFor="facebook" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Facebook
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 dark:border-dark-600 bg-gray-50 dark:bg-dark-600 text-gray-500 dark:text-gray-400 text-sm">
                    facebook.com/
                  </span>
                  <input
                    type="text"
                    id="facebook"
                    name="facebook"
                    value={formData.facebook}
                    onChange={handleChange}
                    className="flex-1 px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-r-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                    placeholder="seu.perfil"
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="twiter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Twitter
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 dark:border-dark-600 bg-gray-50 dark:bg-dark-600 text-gray-500 dark:text-gray-400 text-sm">
                    twitter.com/
                  </span>
                  <input
                    type="text"
                    id="twiter"
                    name="twiter"
                    value={formData.twiter}
                    onChange={handleChange}
                    className="flex-1 px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-r-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                    placeholder="@seuperfil"
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="instagram" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Instagram
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 dark:border-dark-600 bg-gray-50 dark:bg-dark-600 text-gray-500 dark:text-gray-400 text-sm">
                    instagram.com/
                  </span>
                  <input
                    type="text"
                    id="instagram"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleChange}
                    className="flex-1 px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-r-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                    placeholder="seuperfil"
                  />
                </div>
              </div>
              
              <div className="md:col-span-2">
                <label htmlFor="site" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Site
                </label>
                <input
                  type="url"
                  id="site"
                  name="site"
                  value={formData.site}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
                  placeholder="https://seusite.com.br"
                />
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className={`px-6 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors ${
                  loading ? 'opacity-70 cursor-not-allowed' : ''
                }`}
              >
                {loading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Salvando...
                  </span>
                ) : 'Salvar Alterações'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;