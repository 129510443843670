import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { usePlayer } from '../../context/PlayerContext';

const ArtistDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  // Verifica se estamos usando a rota /artists/:id ou /:slug
  const isIdRoute = window.location.pathname.startsWith('/artists/');
  const paramValue = isIdRoute ? params.id : params.slug;
  
  const [artist, setArtist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { playSong, addToQueue } = usePlayer();
  
  // Estados para paginação
  const [albums, setAlbums] = useState([]);
  const [topSongs, setTopSongs] = useState([]);
  const [albumPage, setAlbumPage] = useState(1);
  const [songPage, setSongPage] = useState(1);
  const [loadingMoreAlbums, setLoadingMoreAlbums] = useState(false);
  const [loadingMoreSongs, setLoadingMoreSongs] = useState(false);
  const [hasMoreAlbums, setHasMoreAlbums] = useState(true);
  const [hasMoreSongs, setHasMoreSongs] = useState(true);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    const fetchArtist = async () => {
      try {
        setLoading(true);
        
        // Determina qual endpoint usar com base na rota
        let endpoint;
        if (isIdRoute) {
          // Se estamos na rota /artists/:id, usamos o endpoint de ID
          endpoint = `/artists/${paramValue}`;
        } else {
          // Se estamos na rota /:slug, usamos o endpoint de slug
          endpoint = `/artists/slug/${paramValue}`;
        }
        
        const response = await api.get(endpoint);
        const artistData = response.data;
        
        // Se estamos na rota /artists/:id e o artista tem um slug definido,
        // redirecionamos para a URL com o slug
        if (isIdRoute && artistData.url_slug) {
          console.log('Redirecionando para:', `/${artistData.url_slug}`);
          // Usamos setTimeout para garantir que o redirecionamento ocorra após a renderização
          setTimeout(() => {
            window.location.href = `/${artistData.url_slug}`;
          }, 0);
          return;
        }
        
        setArtist(artistData);
        setAlbums(artistData.albums || []);
        
        // Inicializa topSongs apenas com os primeiros ITEMS_PER_PAGE itens
        const initialSongs = artistData.top_songs ? artistData.top_songs.slice(0, ITEMS_PER_PAGE) : [];
        setTopSongs(initialSongs);
        
        // Verifica se há mais álbuns para carregar
        setHasMoreAlbums((artistData.album_count || 0) > (artistData.albums?.length || 0));
        
        // Verifica se há mais músicas para carregar além das iniciais
        // Para garantir que o botão apareça, definimos hasMoreSongs como true se o artista tiver músicas
        setHasMoreSongs(artistData.top_songs && artistData.top_songs.length > 0);
        
        setLoading(false);
      } catch (err) {
        console.error('Erro ao carregar detalhes do artista:', err);
        setError('Não foi possível carregar os detalhes do artista. Tente novamente mais tarde.');
        setLoading(false);
      }
    };

    fetchArtist();
  }, [isIdRoute, paramValue, navigate]);

  const handlePlaySong = (song) => {
    // Formata o objeto da música para o formato esperado pelo player, similar ao SongDetail
    const formattedSong = {
      id: song.id,
      title: song.title,
      file_path: song.file_path, // Usa o file_path diretamente em vez de construir a URL
      artist: song.artist_name || artist?.username || "Artista Desconhecido",
      album: "Músicas Populares", // Informação padrão já que não temos o álbum específico
      image_url: artist?.profile_image || null,
      plays: song.plays || 0,
      downloads: song.downloads || 0,
      views: song.views || 0,
      // Adiciona os metadados pré-processados
      metadata: song.metadata || {
        title: song.title,
        artist: song.artist_name || artist?.username || "Artista Desconhecido",
        album: "Músicas Populares",
        artwork: artist?.profile_image || null,
        duration: 0
      }
    };
    
    playSong(formattedSong);
  };

  // Função para adicionar música à fila, similar ao SongDetail
  const handleAddToQueue = (song) => {
    // Formata o objeto da música para o formato esperado pelo player
    const formattedSong = {
      id: song.id,
      title: song.title,
      file_path: song.file_path,
      artist: song.artist_name || artist?.username || "Artista Desconhecido",
      album: "Músicas Populares",
      image_url: artist?.profile_image || null,
      plays: song.plays || 0,
      downloads: song.downloads || 0,
      views: song.views || 0,
      // Adiciona os metadados pré-processados
      metadata: song.metadata || {
        title: song.title,
        artist: song.artist_name || artist?.username || "Artista Desconhecido",
        album: "Músicas Populares",
        artwork: artist?.profile_image || null,
        duration: 0
      }
    };
    
    addToQueue(formattedSong);
  };

  // Função para carregar mais álbuns
  const loadMoreAlbums = async () => {
    if (loadingMoreAlbums || !artist) return;
    
    try {
      setLoadingMoreAlbums(true);
      const nextPage = albumPage + 1;
      
      // Usando o endpoint /albums/recent que aceita o parâmetro artist
      const response = await api.get(`/albums/recent`, {
        params: {
          limite: ITEMS_PER_PAGE,
          pag: nextPage,
          artist: artist.id,
          enabled: 1
        }
      });
      
      if (response.data && response.data.length > 0 && response.data[0].name !== "N/A") {
        // Mapear os dados retornados para o formato esperado pelo componente
        const mappedAlbums = response.data.map(album => ({
          id: album.idcd || album.id,
          title: album.name || album.title || album.cdname,
          cover_image: album.poster || album.cover_image || album.cover_url,
          artist_name: album.autor || album.artist_name || artist.username,
          songs_count: album.songs_count || 0
        }));
        
        // Filtrar álbuns que já existem para evitar duplicatas
        const existingIds = albums.map(album => album.id);
        const newAlbums = mappedAlbums.filter(album => !existingIds.includes(album.id));
        
        if (newAlbums.length > 0) {
          setAlbums(prevAlbums => [...prevAlbums, ...newAlbums]);
          setAlbumPage(nextPage);
          setHasMoreAlbums(response.data.length === ITEMS_PER_PAGE);
        } else {
          setHasMoreAlbums(false);
        }
      } else {
        setHasMoreAlbums(false);
      }
    } catch (err) {
      console.error('Erro ao carregar mais álbuns:', err);
    } finally {
      setLoadingMoreAlbums(false);
    }
  };

  // Função para carregar mais músicas
  const loadMoreSongs = async () => {
    if (loadingMoreSongs || !artist) return;
    
    try {
      setLoadingMoreSongs(true);
      
      // Calculamos o valor de skip com base na página atual e no número de itens por página
      const skip = topSongs.length;
      
      // Fazemos uma requisição à API para buscar mais músicas do artista usando o endpoint /songs/
      const response = await api.get(`/songs/`, {
        params: {
          artist_id: artist.id,
          skip: skip,
          limit: ITEMS_PER_PAGE,
          sort_by: 'views' // Ordenamos por visualizações para obter as músicas mais populares
        }
      });
      
      if (response.data && response.data.length > 0) {
        // Filtramos as músicas que já temos para evitar duplicatas
        const existingIds = topSongs.map(song => song.id);
        const newSongs = response.data.filter(song => !existingIds.includes(song.id));
        
        if (newSongs.length > 0) {
          setTopSongs(prevSongs => [...prevSongs, ...newSongs]);
          setSongPage(songPage + 1);
          // Verificamos se ainda há mais músicas para carregar
          setHasMoreSongs(response.data.length === ITEMS_PER_PAGE);
        } else {
          setHasMoreSongs(false);
        }
      } else {
        setHasMoreSongs(false);
      }
    } catch (err) {
      console.error('Erro ao carregar mais músicas:', err);
      setHasMoreSongs(false);
    } finally {
      setLoadingMoreSongs(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Erro!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  if (!artist) {
    return (
      <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Artista não encontrado!</strong>
        <span className="block sm:inline"> O artista solicitado não foi encontrado.</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Cabeçalho do Artista */}
      <div className="relative">
        {artist.cover_image && (
          <div className="w-full h-64 overflow-hidden rounded-lg">
            <img 
              src={artist.cover_image.startsWith('http') ? artist.cover_image : `https://imagens.baladag4.com.br/crop?width=1200&height=400&&file=${artist.cover_image.replace('uploads/', '')}`} 
              alt={`Capa de ${artist.username}`} 
              className="w-full h-full object-cover"
            />
          </div>
        )}
        
        <div className="flex items-end space-x-6 -mt-16 relative z-10 px-4">
          <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-white bg-gray-200">
            <img 
              src={artist.profile_image.startsWith('http') ? artist.profile_image : `https://imagens.baladag4.com.br/crop?width=200&height=200&&file=${artist.profile_image.replace('uploads/', '')}`} 
              alt={artist.username} 
              className="w-full h-full object-cover"
            />
          </div>
          
          <div className="flex-1 bg-white bg-opacity-90 p-4 rounded-lg">
            <h1 className="text-3xl font-bold text-gray-900">{artist.username}</h1>
            <div className="flex space-x-4 mt-2 text-sm text-gray-600">
              <div>
                <span className="font-bold">{artist.album_count}</span> álbuns
              </div>
              <div>
                <span className="font-bold">{artist.song_count}</span> músicas
              </div>
              <div>
                <span className="font-bold">{artist.follower_count}</span> seguidores
              </div>
              {artist.views && (
                <div>
                  <span className="font-bold">{artist.views.toLocaleString()}</span> visualizações
                </div>
              )}
            </div>
            {artist.bio && <p className="mt-2 text-gray-700">{artist.bio}</p>}
          </div>
        </div>
      </div>

      {/* Álbuns do Artista */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2 text-gray-900 dark:text-gray-100">Álbuns Recentes</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {albums.map((album) => (
            <Link to={`/albums/${album.id}`} key={album.id} className="group">
              <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:shadow-xl hover:-translate-y-1">
                <div className="aspect-square overflow-hidden">
                  {album.cover_image ? (
                    <img
                      src={album.cover_image}
                      alt={album.title}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-400">Sem capa</span>
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="font-medium text-gray-900 truncate">{album.title}</h3>
                  <p className="text-sm text-gray-500 mt-1">{album.songs_count} músicas</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {albums.length === 0 && (
          <p className="text-gray-500 italic">Este artista ainda não possui álbuns.</p>
        )}
        
        {hasMoreAlbums && (
          <div className="mt-6 text-center">
            <button
              onClick={loadMoreAlbums}
              disabled={loadingMoreAlbums}
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300 disabled:bg-blue-400"
            >
              {loadingMoreAlbums ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Carregando...
                </span>
              ) : (
                'Carregar mais álbuns'
              )}
            </button>
          </div>
        )}
      </div>

      {/* Músicas Populares */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2 text-gray-900 dark:text-gray-100">Músicas Populares</h2>
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {topSongs.map((song, index) => (
            <div
              key={song.id}
              className={`flex items-center p-4 hover:bg-gray-50 ${index !== topSongs.length - 1 ? 'border-b border-gray-200' : ''}`}
            >
              <div className="w-8 text-center text-gray-500">{index + 1}</div>
              <div className="ml-4 flex-1">
                <h3 className="font-medium text-gray-900">{song.title}</h3>
                <p className="text-sm text-gray-500">{song.views.toLocaleString()} visualizações</p>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handlePlaySong(song)}
                  className="px-3 py-1 bg-primary-600 hover:bg-primary-700 text-white text-sm rounded-md"
                >
                  Reproduzir
                </button>
                <button
                  onClick={() => handleAddToQueue(song)}
                  className="px-3 py-1 bg-secondary-600 hover:bg-secondary-700 text-white text-sm rounded-md"
                >
                  Adicionar à fila
                </button>
              </div>
            </div>
          ))}
          {topSongs.length === 0 && (
            <p className="text-gray-500 italic p-4">Este artista ainda não possui músicas.</p>
          )}
        </div>
        
        {hasMoreSongs && (
          <div className="mt-6 text-center">
            <button
              onClick={loadMoreSongs}
              disabled={loadingMoreSongs}
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300 disabled:bg-blue-400"
            >
              {loadingMoreSongs ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Carregando...
                </span>
              ) : (
                'Carregar mais músicas'
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistDetail;