import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

/**
 * Componente para exibir o formulário de login
 */
const LoginForm = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [status, setStatus] = useState({
    submitting: false,
    error: null
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setStatus({
      submitting: true,
      error: null
    });
    
    try {
      // Usa a função login do contexto de autenticação
      const success = await login(formData.email, formData.password);
      
      if (success) {
        // Se o usuário marcou "Lembrar-me", armazena o email
        if (formData.remember) {
          localStorage.setItem('rememberedEmail', formData.email);
        } else {
          localStorage.removeItem('rememberedEmail');
        }
        
        // Redireciona para a página inicial ou para a página que o usuário estava tentando acessar
        navigate('/');
      } else {
        throw new Error('Falha na autenticação');
      }
    } catch (err) {
      setStatus({
        submitting: false,
        error: 'Email ou senha incorretos. Tente novamente.'
      });
      console.error('Erro ao fazer login:', err);
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <form onSubmit={handleSubmit} className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-center text-gray-800 dark:text-white mb-6">Entrar na sua conta</h2>
        
        {status.error && (
          <div className="bg-red-100 dark:bg-red-900 border border-red-400 text-red-700 dark:text-red-200 px-4 py-3 rounded relative mb-6" role="alert">
            <span className="block sm:inline">{status.error}</span>
          </div>
        )}
        
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 dark:text-gray-300 mb-2">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
          />
        </div>
        
        <div className="mb-6">
          <label htmlFor="password" className="block text-gray-700 dark:text-gray-300 mb-2">Senha</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
          />
        </div>
        
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="remember"
              name="remember"
              checked={formData.remember}
              onChange={handleChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label htmlFor="remember" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
              Lembrar-me
            </label>
          </div>
          
          <div className="text-sm">
            <Link to="/forgot-password" className="text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300">
              Esqueceu a senha?
            </Link>
          </div>
        </div>
        
        <button
          type="submit"
          disabled={status.submitting}
          className="w-full px-6 py-3 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 transition-colors"
        >
          {status.submitting ? 'Entrando...' : 'Entrar'}
        </button>
        
        <div className="mt-6 text-center">
          <p className="text-gray-700 dark:text-gray-300">
            Não tem uma conta?{' '}
            <Link to="/register" className="text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300">
              Cadastre-se
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;