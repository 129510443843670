import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

const MySongsPage = () => {
  const { currentUser } = useAuth();
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('active'); // 'active', 'all', 'approved', 'pending', 'inactive'
  const [sortBy, setSortBy] = useState('date'); // 'date', 'views', 'title'
  const [deleteLoading, setDeleteLoading] = useState(false);
  
  // Função para verificar se a música pode ser deletada (menos de 24 horas)
  const canDeleteSong = (song) => {
    const createdAt = new Date(song.data || song.created_at);
    const now = new Date();
    const diffHours = (now - createdAt) / (1000 * 60 * 60);
    return diffHours < 24;
  };
  
  // Função para deletar uma música
  const handleDeleteSong = async (songId) => {
    if (deleteLoading) return;
    
    if (window.confirm('Tem certeza que deseja deletar esta música?')) {
      try {
        setDeleteLoading(true);
        await api.delete(`/songs/${songId}`);
        
        // Atualiza a lista de músicas
        setSongs(songs.filter(song => song.id !== songId));
        alert('Música deletada com sucesso!');
      } catch (error) {
        console.error('Erro ao deletar música:', error);
        const errorMessage = error.response?.data?.detail || 'Erro ao deletar música';
        alert(errorMessage);
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchSongs = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        // Usa o endpoint /users/me com o parâmetro include=songs
        const response = await api.get('/users/me?include=songs');
        setSongs(response.data?.songs || []);
      } catch (error) {
        console.error('Erro ao buscar músicas:', error);
        // Em caso de erro, usamos dados simulados para demonstração
        setSongs([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSongs();
  }, [currentUser]);

  // Filtrar músicas com base no status selecionado
  const filteredSongs = songs.filter(song => {
    // Garantir que estamos usando o campo correto (aprovado ou approved)
    const status = song.aprovado !== undefined ? song.aprovado : (song.approved ? 1 : 0);
    
    if (filter === 'active') return status === 0 || status === 1;
    if (filter === 'all') return true;
    if (filter === 'approved') return status === 1;
    if (filter === 'pending') return status === 0;
    if (filter === 'inactive') return status >= 2;
    return true;
  });

  // Ordenar músicas
  const sortedSongs = [...filteredSongs].sort((a, b) => {
    if (sortBy === 'date') {
      return new Date(b.data || b.created_at) - new Date(a.data || a.created_at);
    }
    if (sortBy === 'views') {
      return (b.visualizacoes || 0) - (a.visualizacoes || 0);
    }
    if (sortBy === 'title') {
      return (a.nome || a.title || '').localeCompare(b.nome || b.title || '');
    }
    return 0;
  });

  // Função para formatar data
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  };

  // Função para obter o status da música
  const getSongStatus = (song) => {
    // Garantir que estamos usando o campo correto (aprovado ou approved)
    const status = song.aprovado !== undefined ? song.aprovado : (song.approved ? 1 : 0);
    
    if (status === 1) return 'Aprovado';
    if (status === 0) return 'Pendente';
    if (status === 2) return 'Reprovado';
    if (status === 3) return 'Inativo';
    return 'Desconhecido';
  };

  // Função para obter a classe CSS com base no status
  const getStatusClass = (song) => {
    // Garantir que estamos usando o campo correto (aprovado ou approved)
    const status = song.aprovado !== undefined ? song.aprovado : (song.approved ? 1 : 0);
    
    if (status === 1) return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300';
    if (status === 0) return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300';
    if (status === 2) return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300';
    if (status === 3) return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300';
    return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300';
  };

  // Função para formatar duração da música (em segundos)
  const formatDuration = (seconds) => {
    if (!seconds) return '00:00';
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Minhas Músicas
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Gerencie suas músicas.
        </p>
      </div>

      {/* Filtros */}
      <div className="mb-6 p-4 bg-white dark:bg-dark-800 rounded-lg shadow-md">
        <div className="flex flex-wrap items-center gap-4">
          <div>
            <label htmlFor="filter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Filtrar por status
            </label>
            <select
              id="filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="active">Ativos (Aprovados ou Pendentes)</option>
              <option value="all">Todos os Status</option>
              <option value="approved">Aprovados</option>
              <option value="pending">Pendentes</option>
              <option value="inactive">Inativos</option>
            </select>
          </div>
          
          <div>
            <label htmlFor="sortBy" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Ordenar por
            </label>
            <select
              id="sortBy"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="date">Data</option>
              <option value="views">Visualizações</option>
              <option value="title">Título</option>
            </select>
          </div>
          
          <div className="ml-auto">
            <Link
              to="/dashboard/upload-song"
              className="px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors"
            >
              Adicionar Música
            </Link>
          </div>
        </div>
      </div>

      {/* Lista de músicas */}
      {sortedSongs.length > 0 ? (
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 dark:bg-dark-700">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Título
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Data
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Visualizações
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-dark-600">
                {sortedSongs.map((song) => (
                  <tr key={song.id} className="hover:bg-gray-50 dark:hover:bg-dark-700">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10 bg-gray-200 dark:bg-dark-600 rounded flex items-center justify-center">
                          <svg className="w-6 h-6 text-gray-400 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z"></path>
                          </svg>
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900 dark:text-white">
                            {song.nome || song.title}
                          </div>
                          <div className="text-sm text-gray-500 dark:text-gray-400">
                            {formatDuration(song.duration)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {formatDate(song.data || song.created_at)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-white">
                        {song.visualizacoes || 0}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusClass(song)}`}>
                        {getSongStatus(song)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`/songs/${song.id}`} className="text-primary-600 dark:text-primary-400 hover:text-primary-900 dark:hover:text-primary-300 mr-4">
                        Ver
                      </Link>
                      {canDeleteSong(song) ? (
                        <button
                          className="text-red-500 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300"
                          onClick={() => handleDeleteSong(song.id)}
                          disabled={deleteLoading}
                          title="Deletar música"
                        >
                          <svg className="w-5 h-5 inline" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
                          </svg>
                        </button>
                      ) : (
                        <button
                          className="text-gray-400 dark:text-gray-600 cursor-not-allowed"
                          title="Não é possível deletar músicas com mais de 24 horas"
                          disabled
                        >
                          <svg className="w-5 h-5 inline" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
                          </svg>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-8 text-center">
          <svg className="w-16 h-16 mx-auto text-gray-400 dark:text-gray-600 mb-4" fill="currentColor" viewBox="0 0 20 20">
            <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z"></path>
          </svg>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Nenhuma música encontrada</h3>
          <p className="text-gray-500 dark:text-gray-400 mb-6">
            {filter === 'all'
              ? 'Você ainda não adicionou nenhuma música.'
              : filter === 'active'
                ? 'Você não possui músicas ativas (aprovadas ou pendentes).'
                : `Você não possui músicas ${
                    filter === 'approved' ? 'aprovadas' :
                    filter === 'pending' ? 'pendentes' :
                    'inativas'
                  }.`
            }
          </p>
          <Link
            to="/dashboard/upload-song"
            className="px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors"
          >
            Adicionar sua primeira música
          </Link>
        </div>
      )}
    </div>
  );
};

export default MySongsPage;