import React, { useState } from 'react';
import api from '../../services/api';

/**
 * Componente para exibir o formulário de contato
 */
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState({
    submitting: false,
    success: false,
    error: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setStatus({
      submitting: true,
      success: false,
      error: null
    });
    
    try {
      await api.post('/contact/', formData);
      
      setStatus({
        submitting: false,
        success: true,
        error: null
      });
      
      // Limpa o formulário após o envio bem-sucedido
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      
      // Reseta o status de sucesso após 5 segundos
      setTimeout(() => {
        setStatus(prev => ({
          ...prev,
          success: false
        }));
      }, 5000);
      
    } catch (err) {
      setStatus({
        submitting: false,
        success: false,
        error: 'Erro ao enviar a mensagem. Tente novamente.'
      });
      console.error('Erro ao enviar mensagem de contato:', err);
    }
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <h2 className="text-2xl font-bold text-primary-600 dark:text-primary-400 mb-4">Entre em Contato</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">
            Tem alguma dúvida, sugestão ou feedback? Preencha o formulário ao lado e entraremos em contato o mais breve possível.
          </p>
          
          <div className="space-y-4 mt-8">
            <div className="flex items-start">
              <div className="flex-shrink-0 text-primary-500">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </div>
              <div className="ml-3 text-gray-700 dark:text-gray-300">
                <p className="text-sm font-medium">Email</p>
                <p>contato@baladag4.com.br</p>
              </div>
            </div>
            
            <div className="flex items-start">
              <div className="flex-shrink-0 text-primary-500">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
              </div>
              <div className="ml-3 text-gray-700 dark:text-gray-300">
                <p className="text-sm font-medium">Telefone</p>
                <p>(11) 99999-9999</p>
              </div>
            </div>
            
            <div className="flex items-start">
              <div className="flex-shrink-0 text-primary-500">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </div>
              <div className="ml-3 text-gray-700 dark:text-gray-300">
                <p className="text-sm font-medium">Endereço</p>
                <p>Av. Paulista, 1000 - São Paulo, SP</p>
              </div>
            </div>
          </div>
        </div>
        
        <div>
          <form onSubmit={handleSubmit} className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {status.success && (
              <div className="bg-green-100 dark:bg-green-900 border border-green-400 text-green-700 dark:text-green-200 px-4 py-3 rounded relative mb-6" role="alert">
                <span className="block sm:inline">Mensagem enviada com sucesso! Entraremos em contato em breve.</span>
              </div>
            )}
            
            {status.error && (
              <div className="bg-red-100 dark:bg-red-900 border border-red-400 text-red-700 dark:text-red-200 px-4 py-3 rounded relative mb-6" role="alert">
                <span className="block sm:inline">{status.error}</span>
              </div>
            )}
            
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 dark:text-gray-300 mb-2">Nome</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
              />
            </div>
            
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 dark:text-gray-300 mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
              />
            </div>
            
            <div className="mb-4">
              <label htmlFor="subject" className="block text-gray-700 dark:text-gray-300 mb-2">Assunto</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
              />
            </div>
            
            <div className="mb-6">
              <label htmlFor="message" className="block text-gray-700 dark:text-gray-300 mb-2">Mensagem</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
                className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
              ></textarea>
            </div>
            
            <button
              type="submit"
              disabled={status.submitting}
              className="w-full px-6 py-3 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 transition-colors"
            >
              {status.submitting ? 'Enviando...' : 'Enviar Mensagem'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;