import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import api from '../../services/api';
import logo from '../../assets/images/logo.svg';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  // Busca as categorias da API
  const { data: categories, isLoading } = useQuery('categories', async () => {
    const response = await api.get('/categories/');
    return response.data;
  }, {
    staleTime: 1000 * 60 * 60, // 1 hora
  });

  // Verifica se o link está ativo
  const isActive = (path) => {
    return location.pathname === path;
  };

  // Função para alternar a visibilidade da barra lateral em dispositivos móveis
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Botão para abrir a barra lateral em dispositivos móveis */}
      <button
        onClick={toggleSidebar}
        className="fixed bottom-4 left-4 z-40 md:hidden bg-primary-600 text-white p-3 rounded-full shadow-lg"
        aria-label="Abrir menu lateral"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>

      {/* Overlay para fechar a barra lateral em dispositivos móveis */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Barra lateral */}
      <aside
        className={`${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } fixed inset-y-0 left-0 z-50 w-64 bg-white dark:bg-dark-800 shadow-lg transform transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}
      >
        <div className="h-full flex flex-col overflow-y-auto">
          {/* Cabeçalho da barra lateral para dispositivos móveis */}
          <div className="p-4 border-b border-gray-200 dark:border-dark-600 flex justify-between items-center md:hidden">
            <h2 className="text-lg font-semibold text-gray-800 dark:text-white">Menu</h2>
            <button
              onClick={toggleSidebar}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
              aria-label="Fechar menu lateral"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Links de navegação */}
          <nav className="flex-1 px-4 py-6 space-y-6">
            {/* Seção principal */}
            <div>
              <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider dark:text-gray-400">
                Principal
              </h3>
              <div className="mt-3 space-y-1">
                <Link
                  to="/"
                  className={`group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    isActive('/') 
                      ? 'bg-primary-100 text-primary-700 dark:bg-primary-900 dark:text-primary-100' 
                      : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-dark-700'
                  }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Início
                </Link>
                <Link
                  to="/albums"
                  className={`group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    isActive('/albums') 
                      ? 'bg-primary-100 text-primary-700 dark:bg-primary-900 dark:text-primary-100' 
                      : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-dark-700'
                  }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                  Álbuns
                </Link>
                <Link
                  to="/songs"
                  className={`group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    isActive('/songs') 
                      ? 'bg-primary-100 text-primary-700 dark:bg-primary-900 dark:text-primary-100' 
                      : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-dark-700'
                  }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                  </svg>
                  Músicas
                </Link>
                <Link
                  to="/artists"
                  className={`group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    isActive('/artists') 
                      ? 'bg-primary-100 text-primary-700 dark:bg-primary-900 dark:text-primary-100' 
                      : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-dark-700'
                  }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  Artistas
                </Link>
              </div>
            </div>

            {/* Seção de categorias */}
            <div>
              <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider dark:text-gray-400">
                Categorias
              </h3>
              <div className="mt-3 space-y-1">
                {isLoading ? (
                  <div className="px-3 py-2 text-sm text-gray-500 dark:text-gray-400">
                    Carregando...
                  </div>
                ) : (
                  categories?.map((category) => (
                    <Link
                      key={category.id}
                      to={`/category/${category.id}`}
                      className="group flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-dark-700"
                    >
                      <span className="truncate">{category.name}</span>
                    </Link>
                  ))
                )}
              </div>
            </div>

            {/* Seção de informações */}
            <div>
              <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider dark:text-gray-400">
                Informações
              </h3>
              <div className="mt-3 space-y-1">
                <Link
                  to="/about"
                  className={`group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    isActive('/about') 
                      ? 'bg-primary-100 text-primary-700 dark:bg-primary-900 dark:text-primary-100' 
                      : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-dark-700'
                  }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Sobre
                </Link>
                <Link
                  to="/contact"
                  className={`group flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    isActive('/contact') 
                      ? 'bg-primary-100 text-primary-700 dark:bg-primary-900 dark:text-primary-100' 
                      : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-dark-700'
                  }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  Contato
                </Link>
              </div>
            </div>
          </nav>

          {/* Rodapé da barra lateral */}
          <div className="p-4 border-t border-gray-200 dark:border-dark-600">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-auto dark:invert"
                  src={logo}
                  alt="Balada G4"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-700 dark:text-gray-200">
                  Balada G4
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  v1.0.0
                </p>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;