import axios from 'axios';

// Cria uma instância do axios com configurações padrão
const api = axios.create({
  baseURL: (window.ENV && window.ENV.REACT_APP_API_URL) || process.env.REACT_APP_API_URL || 'http://localhost:8000',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Importante para CORS com credenciais
});

// Interceptor para adicionar o token de autenticação a todas as requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para tratar erros de resposta
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Se o erro for 401 (não autorizado), limpa o token e redireciona para o login
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/auth/login'; // Mantém a rota do React Router
    }
    return Promise.reject(error);
  }
);

// Função para obter uma música aleatória com base nos metadados da última música tocada
export const getRandomSong = async (metadata) => {
  try {
    const response = await api.post('/songs/random', { metadata });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar música aleatória:', error);
    throw error;
  }
};

export default api;