import React, { createContext, useState, useContext, useEffect } from 'react';

// Cria o contexto de tema
const ThemeContext = createContext();

// Hook personalizado para usar o contexto de tema
export const useTheme = () => {
  return useContext(ThemeContext);
};

// Provedor do contexto de tema
export const ThemeProvider = ({ children }) => {
  // Verifica se há uma preferência de tema salva no localStorage
  // ou usa a preferência do sistema
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    
    if (savedTheme) {
      return savedTheme;
    }
    
    // Verifica a preferência do sistema
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };

  const [theme, setTheme] = useState(getInitialTheme);

  // Atualiza o localStorage quando o tema muda
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Função para alternar entre os temas
  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  // Valor do contexto
  const value = {
    theme,
    toggleTheme,
    isDark: theme === 'dark'
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};