import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { getRandomSong } from '../services/api';

// Criando o contexto
const PlayerContext = createContext();

// Hook personalizado para usar o contexto
export const usePlayer = () => useContext(PlayerContext);

// Provedor do contexto
export const PlayerProvider = ({ children }) => {
  // Estado do player
  const [currentTrack, setCurrentTrack] = useState(null);
  const [queue, setQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.7);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [bufferedProgress, setBufferedProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  // Referência para o elemento de áudio
  const audioRef = useRef(null);

  // Função para transformar a URL da música para o formato correto
  const transformSongUrl = (path) => {
    if (!path) return '';
    
    // Verifica se o caminho já está no formato correto
    if (path.startsWith('https://')) {
      return path;
    }
    
    // Remove o prefixo "uploads/cd/" e adiciona o novo prefixo
    if (path.startsWith('uploads/cd/')) {
      const parts = path.split('/');
      if (parts.length >= 3) {
        const albumId = parts[2];
        const fileName = parts.slice(3).join('/');
        return `https://s3.uptotal.com/g4cds/baladag4.com.br_${albumId}/${fileName}`;
      }
    }
    
    // Remove o prefixo "uploads/musicas/" e adiciona o novo prefixo para músicas
    if (path.startsWith('uploads/musicas/')) {
      const fileName = path.replace('uploads/musicas/', '');
      return `https://s3.uptotal.com/g4musicas/${fileName}`;
    }
    
    // Se não conseguir transformar, retorna o caminho original
    return path;
  };

  // Função para tocar a próxima música (declarada antes do useEffect para evitar referência circular)
  const playNextTrack = () => {
    if (queue.length > 0) {
      try {
        const nextTrack = queue[0];
        const newQueue = queue.slice(1);
        
        console.log(`Reproduzindo próxima música: ${nextTrack.title}`);
        console.log(`URL da próxima música: ${nextTrack.file_url}`);
        
        setCurrentTrack(nextTrack);
        setQueue(newQueue);
        setCurrentTime(0);
        setBufferedProgress(0);
        setIsPlaying(true);
        
        // Reproduz automaticamente quando o áudio estiver carregado
        setTimeout(() => {
          if (audioRef.current) {
            // Pausa qualquer reprodução atual
            audioRef.current.pause();
            
            // Define a nova fonte explicitamente
            audioRef.current.src = nextTrack.file_url;
            
            // Verifica se temos metadados pré-processados
            if (nextTrack.metadata) {
              console.log("Usando metadados pré-processados para próxima música:", nextTrack.metadata);
              
              // Define a duração se disponível nos metadados
              if (nextTrack.metadata.duration && nextTrack.metadata.duration > 0) {
                setDuration(nextTrack.metadata.duration);
              }
              
              // Inicia a reprodução diretamente sem esperar pelo carregamento de metadados
              audioRef.current.play()
                .then(() => {
                  console.log("Reprodução da próxima música iniciada com sucesso usando metadados pré-processados");
                })
                .catch(error => {
                  console.error("Erro ao reproduzir próxima música com metadados pré-processados:", error);
                  
                  // Tenta novamente após um curto período
                  setTimeout(() => {
                    audioRef.current.play()
                      .then(() => console.log("Reprodução da próxima música iniciada na segunda tentativa"))
                      .catch(e => console.error("Falha na segunda tentativa:", e));
                  }, 1000);
                });
            } else {
              // Comportamento antigo para compatibilidade
              console.log("Metadados pré-processados não disponíveis para próxima música, usando comportamento padrão");
              
              // Força o carregamento dos metadados
              audioRef.current.load();
              
              // Configura o manipulador de eventos para reproduzir quando os metadados estiverem carregados
              const playWhenReady = () => {
                console.log("Metadados da próxima música carregados, iniciando reprodução");
                
                // Tenta reproduzir o áudio
                audioRef.current.play()
                  .then(() => {
                    console.log("Reprodução da próxima música iniciada com sucesso");
                    // Força a atualização da duração
                    if (!isNaN(audioRef.current.duration) && audioRef.current.duration > 0) {
                      setDuration(audioRef.current.duration);
                    }
                  })
                  .catch(error => {
                    console.error("Erro ao reproduzir próxima música:", error);
                    
                    // Tenta novamente após um curto período
                    setTimeout(() => {
                      audioRef.current.play()
                        .then(() => console.log("Reprodução da próxima música iniciada na segunda tentativa"))
                        .catch(e => console.error("Falha na segunda tentativa:", e));
                    }, 1000);
                  });
                
                // Remove o event listener para evitar chamadas duplicadas
                audioRef.current.removeEventListener('loadedmetadata', playWhenReady);
              };
              
              // Verifica se os metadados já estão carregados
              if (audioRef.current.readyState >= 2) {
                // Metadados já estão carregados
                playWhenReady();
              } else {
                // Aguarda o carregamento dos metadados
                audioRef.current.addEventListener('loadedmetadata', playWhenReady);
                
                // Timeout para caso os metadados não carreguem
                setTimeout(() => {
                  if (audioRef.current && audioRef.current.paused) {
                    console.log("Timeout de carregamento de metadados da próxima música, tentando reproduzir mesmo assim");
                    audioRef.current.play()
                      .catch(e => console.error("Erro ao reproduzir próxima música após timeout:", e));
                  }
                }, 5000);
              }
            }
          }
        }, 100);
      } catch (error) {
        console.error("Erro ao iniciar reprodução da próxima música:", error);
        // Se houver erro, tenta a próxima música
        setQueue(prevQueue => prevQueue.slice(1));
        setTimeout(() => playNextTrack(), 1000);
      }
    } else {
      // Se não houver mais músicas na fila, para a reprodução
      setIsPlaying(false);
      console.log("Fila vazia, parando reprodução");
    }
  };

  // Efeito para atualizar o tempo atual da música e o buffer
  useEffect(() => {
    const audio = audioRef.current;
    
    if (!audio) return;

    // Função para atualizar o tempo atual e a duração
    const updateTime = () => {
      const currentTimeValue = audio.currentTime || 0;
      setCurrentTime(currentTimeValue);
      
      // Atualiza a duração apenas se for um valor válido
      if (!isNaN(audio.duration) && audio.duration > 0) {
        setDuration(audio.duration);
        
        // Força a atualização do buffer quando o tempo é atualizado
        updateBuffer();
      }
    };

    // Função para lidar com o fim da música
    const handleEnded = () => {
      console.log("Música finalizada, chamando playNextTrack");
      
      // Limpa o buffer e o tempo atual
      setBufferedProgress(0);
      setCurrentTime(0);
      
      // Verifica se há próxima música na fila
      if (queue.length > 0) {
        // Usando setTimeout para garantir que o evento seja processado completamente
        setTimeout(() => {
          console.log("Iniciando próxima música da fila");
          playNextTrack();
        }, 500);
      } else {
        console.log("Não há mais músicas na fila, buscando música aleatória");
        
        // Se não houver mais músicas na fila, busca uma música aleatória
        if (currentTrack && currentTrack.metadata) {
          // Usando setTimeout para garantir que o evento seja processado completamente
          setTimeout(async () => {
            try {
              // Busca uma música aleatória com base nos metadados da música atual
              const randomSong = await getRandomSong(currentTrack.metadata);
              console.log("Música aleatória recebida:", randomSong);
              
              if (randomSong) {
                // Prepara a música para reprodução
                const songToPlay = {
                  id: randomSong.id,
                  title: randomSong.title,
                  artist: randomSong.artist_name,
                  file_path: randomSong.file_path,
                  metadata: randomSong.metadata,
                  image_url: randomSong.metadata?.artwork || null
                };
                
                // Reproduz a música aleatória
                console.log("Reproduzindo música aleatória:", songToPlay.title);
                playSong(songToPlay);
              } else {
                // Se não conseguir obter uma música aleatória, para a reprodução
                setIsPlaying(false);
                console.log("Não foi possível obter uma música aleatória");
                
                // Reinicia o áudio para o início
                if (audioRef.current) {
                  audioRef.current.currentTime = 0;
                }
              }
            } catch (error) {
              console.error("Erro ao buscar música aleatória:", error);
              
              // Em caso de erro, para a reprodução
              setIsPlaying(false);
              
              // Reinicia o áudio para o início
              if (audioRef.current) {
                audioRef.current.currentTime = 0;
              }
            }
          }, 500);
        } else {
          // Se não tiver metadados da música atual, para a reprodução
          setIsPlaying(false);
          console.log("Não há metadados disponíveis para buscar música aleatória");
          
          // Reinicia o áudio para o início
          if (audioRef.current) {
            audioRef.current.currentTime = 0;
          }
        }
      }
    };
    
    // Função para atualizar o buffer
    const updateBuffer = () => {
      try {
        if (audio.buffered && audio.buffered.length > 0 && !isNaN(audio.duration) && audio.duration > 0) {
          const bufferedEnd = audio.buffered.end(audio.buffered.length - 1);
          const bufferedPercent = (bufferedEnd / audio.duration) * 100;
          
          // Limita o valor entre 0 e 100
          const clampedPercent = Math.min(100, Math.max(0, bufferedPercent));
          setBufferedProgress(clampedPercent);
          console.log(`Buffer atualizado: ${clampedPercent.toFixed(2)}%`);
        }
      } catch (error) {
        console.error("Erro ao atualizar buffer:", error);
      }
    };
    
    // Função para lidar com o início do carregamento
    const handleLoadStart = () => {
      console.log('Iniciando carregamento do áudio');
      setIsLoading(true);
      setBufferedProgress(0);
    };
    
    // Função para lidar quando o áudio está pronto para reprodução
    const handleCanPlay = () => {
      console.log('Áudio pronto para reprodução');
      setIsLoading(false);
      updateTime();
      updateBuffer();
    };
    
    // Função para lidar com o carregamento dos metadados
    const handleLoadedMetadata = () => {
      console.log(`Metadados carregados. Duração: ${audio.duration}`);
      if (!isNaN(audio.duration) && audio.duration > 0) {
        setDuration(audio.duration);
        updateBuffer();
      }
    };
    
    // Função para lidar com o buffering
    const handleWaiting = () => {
      console.log('Áudio em espera (buffering)');
      setIsLoading(true);
    };
    
    // Função para lidar com o início da reprodução
    const handlePlaying = () => {
      console.log('Áudio em reprodução');
      setIsLoading(false);
    };
    
    // Função para lidar com erros
    const handleError = (e) => {
      console.error('Erro no elemento de áudio:', e);
      setIsLoading(false);
    };

    // Adiciona os event listeners
    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('progress', updateBuffer);
    audio.addEventListener('loadstart', handleLoadStart);
    audio.addEventListener('canplay', handleCanPlay);
    audio.addEventListener('waiting', handleWaiting);
    audio.addEventListener('playing', handlePlaying);
    audio.addEventListener('durationchange', updateTime);
    audio.addEventListener('error', handleError);

    // Força uma atualização inicial
    if (audio.readyState >= 2) {
      updateTime();
      updateBuffer();
    }

    // Remove os event listeners quando o componente é desmontado
    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('progress', updateBuffer);
      audio.removeEventListener('loadstart', handleLoadStart);
      audio.removeEventListener('canplay', handleCanPlay);
      audio.removeEventListener('waiting', handleWaiting);
      audio.removeEventListener('playing', handlePlaying);
      audio.removeEventListener('durationchange', updateTime);
      audio.removeEventListener('error', handleError);
    };
  }, [queue, playNextTrack]);

  // Efeito para atualizar o volume
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  // Função para formatar o tempo em minutos:segundos
  const formatTime = (time) => {
    if (isNaN(time)) return '0:00';
    
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // Função para alternar entre play e pause
  const togglePlay = () => {
    const audio = audioRef.current;
    
    if (!audio || !currentTrack) return;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    
    setIsPlaying(!isPlaying);
  };

  // Função para atualizar o tempo da música
  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    
    if (!audio) return;
    
    const newTime = (e.target.value / 100) * duration;
    audio.currentTime = newTime;
    setCurrentTime(newTime);
  };

  // Função para atualizar o volume
  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
  };

  // Função para tocar uma música específica
  const playSong = (song) => {
    if (!song) return;
    
    try {
      // Transforma a URL da música
      const fileUrl = transformSongUrl(song.file_path);
      console.log(`Reproduzindo música: ${song.title}`);
      console.log(`URL transformada: ${fileUrl}`);
      
      // Cria um objeto de música com a URL transformada
      const songWithTransformedUrl = {
        ...song,
        file_url: fileUrl
      };
      
      // Atualiza o estado
      setCurrentTrack(songWithTransformedUrl);
      setCurrentTime(0);
      setBufferedProgress(0);
      setIsPlaying(true);
      
      // Reproduz automaticamente quando o áudio estiver carregado
      setTimeout(() => {
        if (audioRef.current) {
          // Pausa qualquer reprodução atual
          audioRef.current.pause();
          
          // Define a nova fonte explicitamente
          audioRef.current.src = fileUrl;
          
          // Verifica se temos metadados pré-processados
          if (song.metadata) {
            console.log("Usando metadados pré-processados:", song.metadata);
            
            // Define a duração se disponível nos metadados
            if (song.metadata.duration && song.metadata.duration > 0) {
              setDuration(song.metadata.duration);
            }
            
            // Inicia a reprodução diretamente sem esperar pelo carregamento de metadados
            audioRef.current.play()
              .then(() => {
                console.log("Reprodução iniciada com sucesso usando metadados pré-processados");
              })
              .catch(error => {
                console.error("Erro ao reproduzir áudio com metadados pré-processados:", error);
                
                // Tenta novamente após um curto período
                setTimeout(() => {
                  audioRef.current.play()
                    .then(() => console.log("Reprodução iniciada na segunda tentativa"))
                    .catch(e => console.error("Falha na segunda tentativa:", e));
                }, 1000);
              });
          } else {
            // Comportamento antigo para compatibilidade
            console.log("Metadados pré-processados não disponíveis, usando comportamento padrão");
            
            // Força o carregamento dos metadados
            audioRef.current.load();
            
            // Configura o manipulador de eventos para reproduzir quando os metadados estiverem carregados
            const playWhenReady = () => {
              console.log("Metadados carregados, iniciando reprodução");
              
              // Tenta reproduzir o áudio
              audioRef.current.play()
                .then(() => {
                  console.log("Reprodução iniciada com sucesso");
                  // Força a atualização da duração
                  if (!isNaN(audioRef.current.duration) && audioRef.current.duration > 0) {
                    setDuration(audioRef.current.duration);
                  }
                })
                .catch(error => {
                  console.error("Erro ao reproduzir áudio:", error);
                  
                  // Tenta novamente após um curto período
                  setTimeout(() => {
                    audioRef.current.play()
                      .then(() => console.log("Reprodução iniciada na segunda tentativa"))
                      .catch(e => console.error("Falha na segunda tentativa:", e));
                  }, 1000);
                });
              
              // Remove o event listener para evitar chamadas duplicadas
              audioRef.current.removeEventListener('loadedmetadata', playWhenReady);
            };
            
            // Verifica se os metadados já estão carregados
            if (audioRef.current.readyState >= 2) {
              // Metadados já estão carregados
              playWhenReady();
            } else {
              // Aguarda o carregamento dos metadados
              audioRef.current.addEventListener('loadedmetadata', playWhenReady);
              
              // Timeout para caso os metadados não carreguem
              setTimeout(() => {
                if (audioRef.current && audioRef.current.paused) {
                  console.log("Timeout de carregamento de metadados, tentando reproduzir mesmo assim");
                  audioRef.current.play()
                    .catch(e => console.error("Erro ao reproduzir após timeout:", e));
                }
              }, 5000);
            }
          }
        }
      }, 100);
    } catch (error) {
      console.error("Erro ao iniciar reprodução:", error);
    }
  };

  // Função para adicionar uma música à fila
  const addToQueue = (song) => {
    if (!song) return;
    
    // Transforma a URL da música
    const songWithTransformedUrl = {
      ...song,
      file_url: transformSongUrl(song.file_path)
    };
    
    setQueue(prevQueue => [...prevQueue, songWithTransformedUrl]);
  };

  // Função para adicionar várias músicas à fila
  const addMultipleToQueue = (songs) => {
    if (!songs || !songs.length) return;
    
    const songsWithTransformedUrls = songs.map(song => ({
      ...song,
      file_url: transformSongUrl(song.file_path)
    }));
    
    setQueue(prevQueue => [...prevQueue, ...songsWithTransformedUrls]);
  };

  // Função para limpar a fila
  const clearQueue = () => {
    setQueue([]);
  };

  // Função para tocar a música anterior
  const playPreviousTrack = () => {
    // Em uma implementação real, isso buscaria a música anterior
    // Por enquanto, apenas reinicia a música atual
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  // Função para reproduzir um álbum inteiro
  const playAlbum = (album) => {
    if (!album || !album.songs || !album.songs.length) return;
    
    // Toca a primeira música
    playSong(album.songs[0]);
    
    // Adiciona o resto à fila
    if (album.songs.length > 1) {
      addMultipleToQueue(album.songs.slice(1));
    }
  };

  // Valor do contexto
  const value = {
    currentTrack,
    queue,
    isPlaying,
    currentTime,
    duration,
    volume,
    isExpanded,
    showVolumeControl,
    audioRef,
    bufferedProgress,
    isLoading,
    formatTime,
    togglePlay,
    handleProgressChange,
    handleVolumeChange,
    playSong,
    playNextTrack,
    playPreviousTrack,
    addToQueue,
    addMultipleToQueue,
    clearQueue,
    playAlbum,
    setIsExpanded,
    setShowVolumeControl,
    transformSongUrl
  };

  return (
    <PlayerContext.Provider value={value}>
      {children}
    </PlayerContext.Provider>
  );
};