import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '../../services/api';
import AlbumCard from './AlbumCard';
import LoadingSpinner from '../common/LoadingSpinner';

/**
 * Componente otimizado para exibir uma lista de álbuns com botão de carregar mais
 * @param {number} limit - Número máximo de álbuns a serem exibidos por página
 * @param {string} searchTerm - Termo de busca para filtrar os álbuns
 * @param {string} sortOrder - Ordenação dos álbuns (recent, az, popular)
 * @param {string|number} categoryId - ID da categoria para filtrar os álbuns
 * @param {string} queryKey - Chave única para o cache do react-query
 */
const CategoryAlbumList = ({ 
  limit = 20, 
  searchTerm = '', 
  sortOrder = 'recent', 
  categoryId = null,
  queryKey = 'categoryAlbums'
}) => {
  const [page, setPage] = useState(1);
  const [allAlbums, setAllAlbums] = useState([]);
  const [hasMoreAlbums, setHasMoreAlbums] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  // Resetar a página e os álbuns quando mudar os filtros
  useEffect(() => {
    setPage(1);
    setAllAlbums([]);
    setHasMoreAlbums(true);
  }, [searchTerm, sortOrder, categoryId]);

  // Busca álbuns da API
  const { data: newAlbums, isLoading: loadingAlbums } = useQuery(
    [queryKey, searchTerm, sortOrder, categoryId, page],
    async () => {
      console.log(`Buscando álbuns: página ${page}, limite ${limit}`);
      const response = await api.get('/albums/', {
        params: {
          limit: limit,
          skip: (page - 1) * limit,
          search: searchTerm || undefined,
          sort: sortOrder || undefined,
          category_id: categoryId || undefined
        }
      });
      console.log(`Álbuns recebidos: ${response.data.length}`);
      return response.data;
    },
    {
      staleTime: 1000 * 60, // 1 minuto
      enabled: true,
      retry: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: false, // Não manter dados anteriores para evitar problemas com o estado
      onError: (error) => {
        console.error('Erro ao carregar álbuns:', error);
        setLoadingMore(false); // Garantir que o estado de loading é resetado em caso de erro
      }
    }
  );

  // Atualiza o array de álbuns quando novos dados são carregados
  useEffect(() => {
    if (newAlbums) {
      // Verifica se há mais álbuns para carregar
      if (newAlbums.length < limit) {
        setHasMoreAlbums(false);
      }
      
      // Adiciona novos álbuns ao array existente, evitando duplicatas
      setAllAlbums(prevAlbums => {
        if (page === 1) {
          // Se for a primeira página, substitui completamente
          return [...newAlbums];
        } else {
          // Se não for a primeira página, adiciona evitando duplicatas
          const albumIds = new Set(prevAlbums.map(album => album.id || album.idcd));
          const uniqueNewAlbums = newAlbums.filter(album => !albumIds.has(album.id || album.idcd));
          return [...prevAlbums, ...uniqueNewAlbums];
        }
      });
    }
  }, [newAlbums, limit, page]);

  // Função para carregar mais itens
  const loadMore = useCallback(() => {
    console.log('Carregando mais álbuns...');
    setLoadingMore(true);
    setPage(prevPage => prevPage + 1);
  }, []);

  // Atualiza o estado de loadingMore quando o carregamento termina
  useEffect(() => {
    if (!loadingAlbums) {
      setLoadingMore(false);
    }
  }, [loadingAlbums]);

  if (loadingAlbums && page === 1) {
    return (
      <div className="flex justify-center items-center h-40">
        <LoadingSpinner size="md" />
      </div>
    );
  }

  if (!allAlbums || allAlbums.length === 0) {
    if (loadingAlbums) {
      return (
        <div className="flex justify-center items-center h-40">
          <LoadingSpinner size="md" />
        </div>
      );
    }
    
    return (
      <div className="bg-gray-100 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 text-gray-700 dark:text-gray-300 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline">Nenhum álbum encontrado.</span>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {allAlbums.map((album) => (
          <AlbumCard key={album.id || album.idcd} album={album} />
        ))}
      </div>
      
      {loadingAlbums && page > 1 && (
        <div className="flex justify-center mt-4">
          <LoadingSpinner size="sm" />
        </div>
      )}
      
      {hasMoreAlbums && (
        <div className="mt-4 text-center">
          <button
            onClick={loadMore}
            disabled={loadingAlbums || loadingMore}
            className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 disabled:opacity-70 flex items-center justify-center mx-auto"
          >
            {loadingAlbums || loadingMore ? (
              <>
                <LoadingSpinner size="sm" color="white" className="mr-2" />
                <span>Carregando...</span>
              </>
            ) : (
              'Carregar mais'
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default CategoryAlbumList;