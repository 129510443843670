import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Componente para exibir um card de álbum
 * @param {Object} album - Objeto contendo os dados do álbum
 */
const AlbumCard = ({ album }) => {
  // Compatibilidade com diferentes formatos de dados da API
  const coverImage = album.cover_url || album.cover_image || album.poster || '/placeholder-album.jpg';
  const albumId = album.id || album.idcd;
  const releaseDate = album.release_date || album.created_at || album.data;

  return (
    <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden transition-transform hover:scale-105">
      <Link to={`/albums/${albumId}`}>
        <div className="relative pb-[100%]">
          <img
            src={coverImage}
            alt={album.title}
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white truncate">
            {album.title}
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-300 mt-1 truncate">
            {album.artist_name}
          </p>
          <div className="flex items-center justify-between mt-2">
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {releaseDate ? new Date(releaseDate).getFullYear() : 'Sem data'}
            </span>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {album.songs_count || 0} músicas
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AlbumCard;