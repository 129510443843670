import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Componente para exibir um card de artista
 * @param {Object} artist - Objeto contendo os dados do artista
 */
const ArtistCard = ({ artist }) => {
  // Usa poster se disponível, senão usa profile_image
  const imageUrl = artist.poster || artist.profile_image || '/placeholder-artist.jpg';
  
  // Usa urlprofile se disponível, senão constrói a URL com o ID
  const profileUrl = artist.urlprofile || `/artists/${artist.id}`;
  
  return (
    <div className="flex items-center bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden p-2 transition-transform hover:scale-105">
      {/* Ranking */}
      {artist.ranking && (
        <div className="flex-shrink-0 w-8 h-8 bg-primary-600 text-white rounded-full flex items-center justify-center mr-3 font-bold">
          {artist.ranking}
        </div>
      )}
      
      {/* Imagem do artista */}
      <div className="flex-shrink-0 w-16 h-16 mr-4">
        <Link to={profileUrl}>
          <img
            src={imageUrl}
            alt={artist.username}
            className="w-full h-full object-cover rounded-full"
          />
        </Link>
      </div>
      
      {/* Informações do artista */}
      <div className="flex-grow">
        <Link to={profileUrl}>
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white truncate">
            {artist.username}
          </h3>
          <div className="flex items-center justify-between">
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {artist.views ? `${artist.views} visualizações` : `${artist.follower_count || 0} seguidores`}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ArtistCard;