import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

const FollowersPage = () => {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('followers'); // 'followers' ou 'following'
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchFollowers = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        // Usa o endpoint /users/me com o parâmetro include=followers,following
        const response = await api.get('/users/me?include=followers,following');
        
        setFollowers(response.data?.followers || []);
        setFollowing(response.data?.following || []);
      } catch (error) {
        console.error('Erro ao buscar seguidores:', error);
        // Em caso de erro, usamos dados simulados para demonstração
        setFollowers([]);
        setFollowing([]);
      } finally {
        setLoading(false);
      }
    };

    fetchFollowers();
  }, [currentUser]);

  // Filtrar usuários com base no termo de pesquisa
  const filteredUsers = (activeTab === 'followers' ? followers : following).filter(user => {
    return user.username?.toLowerCase().includes(searchTerm.toLowerCase()) || 
           user.full_name?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Função para seguir/deixar de seguir um usuário
  const toggleFollow = async (userId, isFollowing) => {
    try {
      if (isFollowing) {
        await api.delete(`/users/${userId}/follow`);
        // Atualiza a lista de seguindo
        setFollowing(following.filter(user => user.id !== userId));
      } else {
        await api.post(`/users/${userId}/follow`);
        // Busca os dados do usuário e adiciona à lista de seguindo
        const response = await api.get(`/users/${userId}`);
        setFollowing([...following, response.data]);
      }
    } catch (error) {
      console.error('Erro ao atualizar seguidor:', error);
    }
  };

  // Função para remover um seguidor
  const removeFollower = async (followerId) => {
    try {
      await api.delete(`/users/${currentUser.id}/followers/${followerId}`);
      // Atualiza a lista de seguidores
      setFollowers(followers.filter(user => user.id !== followerId));
    } catch (error) {
      console.error('Erro ao remover seguidor:', error);
    }
  };

  // Verifica se o usuário está sendo seguido
  const isFollowing = (userId) => {
    return following.some(user => user.id === userId);
  };

  // Função para formatar data
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Seguidores e Seguindo
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Gerencie suas conexões na plataforma.
        </p>
      </div>

      {/* Tabs */}
      <div className="mb-6 border-b border-gray-200 dark:border-dark-600">
        <ul className="flex flex-wrap -mb-px">
          <li className="mr-2">
            <button
              onClick={() => setActiveTab('followers')}
              className={`inline-block py-4 px-4 text-sm font-medium ${
                activeTab === 'followers'
                  ? 'text-primary-600 dark:text-primary-400 border-b-2 border-primary-600 dark:border-primary-400'
                  : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
              }`}
            >
              Seguidores ({followers.length})
            </button>
          </li>
          <li className="mr-2">
            <button
              onClick={() => setActiveTab('following')}
              className={`inline-block py-4 px-4 text-sm font-medium ${
                activeTab === 'following'
                  ? 'text-primary-600 dark:text-primary-400 border-b-2 border-primary-600 dark:border-primary-400'
                  : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
              }`}
            >
              Seguindo ({following.length})
            </button>
          </li>
        </ul>
      </div>

      {/* Barra de pesquisa */}
      <div className="mb-6 p-4 bg-white dark:bg-dark-800 rounded-lg shadow-md">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="w-5 h-5 text-gray-400 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
            </svg>
          </div>
          <input
            type="text"
            placeholder="Buscar usuários..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
          />
        </div>
      </div>

      {/* Lista de usuários */}
      {filteredUsers.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredUsers.map((user) => (
            <div key={user.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {user.profile_image ? (
                      <img
                        src={user.profile_image}
                        alt={user.username}
                        className="h-12 w-12 rounded-full object-cover"
                      />
                    ) : (
                      <div className="h-12 w-12 rounded-full bg-gray-200 dark:bg-dark-700 flex items-center justify-center">
                        <svg className="w-6 h-6 text-gray-400 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="ml-4 flex-1">
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                      {user.username}
                    </h3>
                    {user.full_name && (
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {user.full_name}
                      </p>
                    )}
                  </div>
                  {activeTab === 'followers' && user.id !== currentUser.id && (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => toggleFollow(user.id, isFollowing(user.id))}
                        className={`px-3 py-1 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 ${
                          isFollowing(user.id)
                            ? 'bg-gray-200 dark:bg-dark-700 text-gray-700 dark:text-gray-300'
                            : 'bg-primary-600 hover:bg-primary-700 text-white'
                        }`}
                      >
                        {isFollowing(user.id) ? 'Seguindo' : 'Seguir'}
                      </button>
                      <button
                        onClick={() => removeFollower(user.id)}
                        className="px-3 py-1 text-sm bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-300 rounded-md hover:bg-red-200 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500"
                        title="Remover seguidor"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  )}
                  {activeTab === 'following' && (
                    <button
                      onClick={() => toggleFollow(user.id, true)}
                      className="px-3 py-1 text-sm bg-gray-200 dark:bg-dark-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-dark-600 focus:outline-none focus:ring-2 focus:ring-primary-500"
                    >
                      Seguindo
                    </button>
                  )}
                </div>
                <div className="mt-4 flex items-center justify-between text-sm text-gray-500 dark:text-gray-400">
                  <div>
                    <span className="font-medium text-gray-700 dark:text-gray-300">{user.song_count || 0}</span> músicas
                  </div>
                  <div>
                    <span className="font-medium text-gray-700 dark:text-gray-300">{user.album_count || 0}</span> álbuns
                  </div>
                  <div>
                    Desde {formatDate(user.created_at)}
                  </div>
                </div>
                <div className="mt-4">
                  <Link
                    to={user.url_slug ? `/${user.url_slug}` : `/artists/${user.id}`}
                    className="text-primary-600 dark:text-primary-400 hover:underline text-sm"
                  >
                    Ver perfil
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-8 text-center">
          <svg className="w-16 h-16 mx-auto text-gray-400 dark:text-gray-600 mb-4" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>
          </svg>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
            {activeTab === 'followers' ? 'Nenhum seguidor encontrado' : 'Você não está seguindo ninguém'}
          </h3>
          <p className="text-gray-500 dark:text-gray-400 mb-6">
            {searchTerm 
              ? 'Nenhum usuário corresponde à sua pesquisa.' 
              : activeTab === 'followers' 
                ? 'Compartilhe seu perfil para conseguir mais seguidores.' 
                : 'Comece a seguir outros artistas para ver suas atualizações.'}
          </p>
          {activeTab === 'following' && (
            <Link
              to="/discover"
              className="px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors"
            >
              Descobrir artistas
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default FollowersPage;