import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

const MyAlbumsPage = () => {
  const { currentUser } = useAuth();
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('active'); // 'active', 'all', 'approved', 'pending', 'waiting', 'rejected'
  const [menuOpen, setMenuOpen] = useState(null); // ID do álbum com menu aberto ou null
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Função para verificar se o álbum pode ser deletado (menos de 24 horas)
  const canDeleteAlbum = (album) => {
    const createdAt = new Date(album.created_at);
    const now = new Date();
    const diffHours = (now - createdAt) / (1000 * 60 * 60);
    return diffHours < 24;
  };
  
  // Função para deletar um álbum
  const handleDeleteAlbum = async (albumId) => {
    if (deleteLoading) return;
    
    try {
      setDeleteLoading(true);
      await api.delete(`/albums/${albumId}`);
      
      // Atualiza a lista de álbuns
      setAlbums(albums.filter(album => album.id !== albumId));
      alert('Álbum deletado com sucesso!');
    } catch (error) {
      console.error('Erro ao deletar álbum:', error);
      const errorMessage = error.response?.data?.detail || 'Erro ao deletar álbum';
      alert(errorMessage);
    } finally {
      setDeleteLoading(false);
      setMenuOpen(null); // Fecha o menu
    }
  };
  
  // Função para alternar o menu dropdown
  const toggleMenu = (albumId) => {
    setMenuOpen(menuOpen === albumId ? null : albumId);
  };

  useEffect(() => {
    const fetchAlbums = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        // Usa o endpoint /users/me com o parâmetro include=albums
        const response = await api.get('/users/me?include=albums');
        setAlbums(response.data?.albums || []);
      } catch (error) {
        console.error('Erro ao buscar álbuns:', error);
        // Em caso de erro, usamos dados simulados para demonstração
        setAlbums([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAlbums();
  }, [currentUser]);
  
  // Fecha o menu quando clicar fora
  useEffect(() => {
    const handleClickOutside = () => {
      setMenuOpen(null);
    };
    
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Filtrar álbuns com base no status selecionado
  const filteredAlbums = albums.filter(album => {
    // Obter o status do álbum
    const status = album.status || getAlbumStatus(album).toLowerCase();
    
    // Filtro "active" mostra apenas álbuns com aprovado = 0 ou aprovado = 1
    if (filter === 'active') {
      const aprovado = album.aprovado;
      return aprovado === 0 || aprovado === 1;
    }
    
    // Outros filtros
    if (filter === 'all') return true;
    if (filter === 'approved') return status === 'publicado';
    if (filter === 'pending') return status === 'pendente';
    if (filter === 'waiting') return status === 'aguardando aprovação';
    if (filter === 'rejected') return status === 'reprovado';
    
    return true;
  });

  // Função para formatar data
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  };

  // Função para obter o status do álbum
  const getAlbumStatus = (album) => {
    // Se o álbum já tem um status definido pelo backend, use-o
    if (album.status) {
      const status = album.status.charAt(0).toUpperCase() + album.status.slice(1);
      return status;
    }
    
    // Caso contrário, calcule o status com base nos campos concluido e aprovado
    if (album.aprovado === 2) return 'Reprovado';
    if (album.concluido === 0) return 'Pendente';
    if (album.concluido === 1) {
      if (album.aprovado === 1) return 'Publicado';
      if (album.aprovado === 0) return 'Aguardando aprovação';
    }
    return 'Desconhecido';
  };

  // Função para obter a classe CSS com base no status
  const getStatusClass = (album) => {
    const status = album.status || getAlbumStatus(album).toLowerCase();
    
    switch (status) {
      case 'publicado':
        return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300';
      case 'pendente':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300';
      case 'aguardando aprovação':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300';
      case 'reprovado':
        return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300';
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Meus Álbuns
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Gerencie seus álbuns de música.
        </p>
      </div>

      {/* Filtros */}
      <div className="mb-6 p-4 bg-white dark:bg-dark-800 rounded-lg shadow-md">
        <div className="flex flex-wrap items-center gap-4">
          <div>
            <label htmlFor="filter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Filtrar por status
            </label>
            <select
              id="filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="active">Ativos (Aprovados ou Pendentes)</option>
              <option value="all">Todos os Status</option>
              <option value="approved">Publicados</option>
              <option value="pending">Pendentes</option>
              <option value="waiting">Aguardando aprovação</option>
              <option value="rejected">Reprovados</option>
            </select>
          </div>
          
          <div className="ml-auto">
            <Link
              to="/dashboard/upload-album"
              className="px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors"
            >
              Adicionar Álbum
            </Link>
          </div>
        </div>
      </div>

      {/* Lista de álbuns */}
      {filteredAlbums.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredAlbums.map((album) => (
            <div key={album.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
              <div className="relative pb-[100%]">
                {album.cover_image ? (
                  <img
                    src={album.cover_image}
                    alt={album.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                ) : (
                  <div className="absolute inset-0 w-full h-full bg-gray-200 dark:bg-dark-700 flex items-center justify-center">
                    <svg className="w-16 h-16 text-gray-400 dark:text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                    </svg>
                  </div>
                )}
                <div className="absolute top-2 right-2">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusClass(album)}`}>
                    {getAlbumStatus(album)}
                  </span>
                </div>
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-1">
                  {album.title || album.nome}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                  Adicionado em {formatDate(album.data || album.created_at)}
                </p>
                <div className="flex items-center justify-between mt-2">
                  <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                    <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                      <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path>
                    </svg>
                    {album.views || 0} visualizações
                  </div>
                  <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                    <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z"></path>
                    </svg>
                    {album.songs_count || 0} músicas
                  </div>
                </div>
                <div className="mt-4 flex justify-between relative">
                  <Link
                    to={`/albums/${album.id}`}
                    className="text-primary-600 dark:text-primary-400 hover:underline"
                  >
                    Ver detalhes
                  </Link>
                  <button
                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (canDeleteAlbum(album)) {
                        if (window.confirm('Tem certeza que deseja deletar este álbum?')) {
                          handleDeleteAlbum(album.id);
                        }
                      } else {
                        alert('Não é possível deletar álbuns com mais de 24 horas de publicação.');
                      }
                    }}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-8 text-center">
          <svg className="w-16 h-16 mx-auto text-gray-400 dark:text-gray-600 mb-4" fill="currentColor" viewBox="0 0 20 20">
            <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
          </svg>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Nenhum álbum encontrado</h3>
          <p className="text-gray-500 dark:text-gray-400 mb-6">
            {filter === 'all'
              ? 'Você ainda não adicionou nenhum álbum.'
              : filter === 'active'
                ? 'Você não possui álbuns ativos (aprovados ou pendentes).'
                : `Você não possui álbuns ${
                    filter === 'approved' ? 'publicados' :
                    filter === 'pending' ? 'pendentes' :
                    filter === 'waiting' ? 'aguardando aprovação' :
                    'reprovados'
                  }.`
            }
          </p>
          <Link
            to="/dashboard/upload-album"
            className="px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors"
          >
            Adicionar seu primeiro álbum
          </Link>
        </div>
      )}
    </div>
  );
};

export default MyAlbumsPage;