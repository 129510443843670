import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import api from '../services/api';
import AlbumCard from '../components/albums/AlbumCard';
import SongCard from '../components/songs/SongCard';
import LoadingSpinner from '../components/common/LoadingSpinner';

const CategoryDetailPage = () => {
  const { id } = useParams();
  
  // Referências para os observers
  const albumObserverRef = useRef(null);
  const songObserverRef = useRef(null);
  
  // Referências para os últimos elementos das listas
  const lastAlbumElementRef = useRef(null);
  const lastSongElementRef = useRef(null);
  
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [activeTab, setActiveTab] = useState(0); // 0 para álbuns, 1 para músicas
  const [allAlbums, setAllAlbums] = useState([]);
  const [allSongs, setAllSongs] = useState([]);
  const [hasMoreAlbums, setHasMoreAlbums] = useState(true);
  const [hasMoreSongs, setHasMoreSongs] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [infiniteScrollEnabled, setInfiniteScrollEnabled] = useState(true);

  // Busca informações da categoria
  const { data: category, isLoading: loadingCategory } = useQuery(
    ['category', id],
    async () => {
      const response = await api.get(`/categories/${id}`);
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutos
      retry: 1,
      onError: (error) => {
        console.error('Erro ao carregar categoria:', error);
      }
    }
  );

  // Busca álbuns da categoria
  const { data: newAlbums, isLoading: loadingAlbums } = useQuery(
    ['categoryAlbums', id, page, limit],
    async () => {
      const response = await api.get('/albums/', {
        params: {
          category_id: id,
          skip: (page - 1) * limit,
          limit: limit,
          sort: 'recent'
        }
      });
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutos
      enabled: !!id,
      retry: 1,
      onError: (error) => {
        console.error('Erro ao carregar álbuns da categoria:', error);
      }
    }
  );

  // Busca músicas da categoria
  const { data: newSongs, isLoading: loadingSongs } = useQuery(
    ['categorySongs', id, page, limit],
    async () => {
      const response = await api.get('/songs/', {
        params: {
          category_id: id,
          skip: (page - 1) * limit,
          limit: limit,
          sort_by: 'date'
        }
      });
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutos
      enabled: !!id,
      retry: 1,
      onError: (error) => {
        console.error('Erro ao carregar músicas da categoria:', error);
      }
    }
  );

  // Atualiza o array de álbuns quando novos dados são carregados
  useEffect(() => {
    if (newAlbums) {
      // Verifica se há mais álbuns para carregar
      if (newAlbums.length < limit) {
        setHasMoreAlbums(false);
      }
      
      // Adiciona novos álbuns ao array existente, evitando duplicatas
      setAllAlbums(prevAlbums => {
        const albumIds = new Set(prevAlbums.map(album => album.id || album.idcd));
        const uniqueNewAlbums = newAlbums.filter(album => !albumIds.has(album.id || album.idcd));
        return [...prevAlbums, ...uniqueNewAlbums];
      });
    }
  }, [newAlbums, limit]);

  // Atualiza o array de músicas quando novos dados são carregados
  useEffect(() => {
    if (newSongs) {
      // Verifica se há mais músicas para carregar
      if (newSongs.length < limit) {
        setHasMoreSongs(false);
      }
      
      // Adiciona novas músicas ao array existente, evitando duplicatas
      setAllSongs(prevSongs => {
        const songIds = new Set(prevSongs.map(song => song.id));
        const uniqueNewSongs = newSongs.filter(song => !songIds.has(song.id));
        return [...prevSongs, ...uniqueNewSongs];
      });
    }
  }, [newSongs, limit]);

  // Função para carregar mais itens
  const loadMore = () => {
    setLoadingMore(true);
    setPage(prevPage => prevPage + 1);
  };

  // Atualiza o estado de loadingMore quando o carregamento termina
  useEffect(() => {
    if (!loadingAlbums && !loadingSongs) {
      setLoadingMore(false);
    }
  }, [loadingAlbums, loadingSongs]);

  // Função para carregar mais itens quando o usuário chegar ao fim da página (álbuns)
  const handleAlbumObserver = useCallback((entries) => {
    const [target] = entries;
    if (target.isIntersecting && hasMoreAlbums && !loadingAlbums && !loadingMore && infiniteScrollEnabled && activeTab === 0) {
      loadMore();
    }
  }, [hasMoreAlbums, loadingAlbums, loadingMore, infiniteScrollEnabled, activeTab]);

  // Função para carregar mais itens quando o usuário chegar ao fim da página (músicas)
  const handleSongObserver = useCallback((entries) => {
    const [target] = entries;
    if (target.isIntersecting && hasMoreSongs && !loadingSongs && !loadingMore && infiniteScrollEnabled && activeTab === 1) {
      loadMore();
    }
  }, [hasMoreSongs, loadingSongs, loadingMore, infiniteScrollEnabled, activeTab]);

  // Configurar o Intersection Observer para álbuns
  useEffect(() => {
    const observer = new IntersectionObserver(handleAlbumObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    
    albumObserverRef.current = observer;
    
    if (lastAlbumElementRef.current) {
      observer.observe(lastAlbumElementRef.current);
    }
    
    return () => {
      if (albumObserverRef.current) {
        albumObserverRef.current.disconnect();
      }
    };
  }, [handleAlbumObserver, allAlbums]);

  // Configurar o Intersection Observer para músicas
  useEffect(() => {
    const observer = new IntersectionObserver(handleSongObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    
    songObserverRef.current = observer;
    
    if (lastSongElementRef.current) {
      observer.observe(lastSongElementRef.current);
    }
    
    return () => {
      if (songObserverRef.current) {
        songObserverRef.current.disconnect();
      }
    };
  }, [handleSongObserver, allSongs]);

  if (loadingCategory) {
    return (
      <div className="container mx-auto px-4 py-8 flex justify-center">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (!category) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6 text-center">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
            Categoria não encontrada
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            A categoria que você está procurando não existe ou foi removida.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800 dark:text-white mb-2">
          {category.nome}
        </h1>
        <p className="text-gray-600 dark:text-gray-300">
          Explore os álbuns e músicas da categoria {category.nome}
        </p>
      </div>

      <div className="mb-8">
        {/* Abas personalizadas */}
        <div className="flex border-b border-gray-200 dark:border-dark-700 mb-4">
          <button
            className={`px-4 py-2 font-medium cursor-pointer border-b-2 ${
              activeTab === 0
                ? 'border-primary-600 text-primary-600'
                : 'border-transparent hover:text-primary-600 hover:border-primary-600'
            } focus:outline-none`}
            onClick={() => setActiveTab(0)}
          >
            Álbuns
          </button>
          <button
            className={`px-4 py-2 font-medium cursor-pointer border-b-2 ${
              activeTab === 1
                ? 'border-primary-600 text-primary-600'
                : 'border-transparent hover:text-primary-600 hover:border-primary-600'
            } focus:outline-none`}
            onClick={() => setActiveTab(1)}
          >
            Músicas
          </button>
        </div>

        {/* Conteúdo da aba selecionada */}
        {activeTab === 0 && (
          <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {loadingAlbums && page === 1 ? (
              <div className="flex justify-center py-8">
                <LoadingSpinner size="md" />
              </div>
            ) : allAlbums && allAlbums.length > 0 ? (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                  {allAlbums.map((album, index) => {
                    // Adiciona a referência ao último elemento
                    if (index === allAlbums.length - 1) {
                      return (
                        <div key={album.id || album.idcd} ref={lastAlbumElementRef}>
                          <AlbumCard album={album} />
                        </div>
                      );
                    } else {
                      return <AlbumCard key={album.id || album.idcd} album={album} />;
                    }
                  })}
                </div>
                {loadingAlbums && page > 1 && (
                  <div className="flex justify-center mt-4">
                    <LoadingSpinner size="sm" />
                  </div>
                )}
                
                {/* Opção para ativar/desativar scroll infinito */}
                <div className="flex items-center justify-center mt-4">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={infiniteScrollEnabled}
                      onChange={() => setInfiniteScrollEnabled(!infiniteScrollEnabled)}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Scroll infinito
                    </span>
                  </label>
                </div>
                
                {hasMoreAlbums && (
                  <div className="mt-4 text-center">
                    <button
                      onClick={loadMore}
                      disabled={loadingAlbums || loadingMore}
                      className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 disabled:opacity-70 flex items-center justify-center mx-auto"
                    >
                      {loadingAlbums || loadingMore ? (
                        <>
                          <LoadingSpinner size="sm" color="white" className="mr-2" />
                          <span>Carregando...</span>
                        </>
                      ) : (
                        'Carregar mais'
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <p className="text-gray-600 dark:text-gray-300 text-center py-8">
                Nenhum álbum encontrado nesta categoria.
              </p>
            )}
          </div>
        )}

        {activeTab === 1 && (
          <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {loadingSongs && page === 1 ? (
              <div className="flex justify-center py-8">
                <LoadingSpinner size="md" />
              </div>
            ) : allSongs && allSongs.length > 0 ? (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                  {allSongs.map((song, index) => {
                    // Adiciona a referência ao último elemento
                    if (index === allSongs.length - 1) {
                      return (
                        <div key={song.id} ref={lastSongElementRef}>
                          <SongCard song={song} />
                        </div>
                      );
                    } else {
                      return <SongCard key={song.id} song={song} />;
                    }
                  })}
                </div>
                {loadingSongs && page > 1 && (
                  <div className="flex justify-center mt-4">
                    <LoadingSpinner size="sm" />
                  </div>
                )}
                
                {/* Opção para ativar/desativar scroll infinito */}
                <div className="flex items-center justify-center mt-4">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={infiniteScrollEnabled}
                      onChange={() => setInfiniteScrollEnabled(!infiniteScrollEnabled)}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Scroll infinito
                    </span>
                  </label>
                </div>
                
                {hasMoreSongs && (
                  <div className="mt-4 text-center">
                    <button
                      onClick={loadMore}
                      disabled={loadingSongs || loadingMore}
                      className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 disabled:opacity-70 flex items-center justify-center mx-auto"
                    >
                      {loadingSongs || loadingMore ? (
                        <>
                          <LoadingSpinner size="sm" color="white" className="mr-2" />
                          <span>Carregando...</span>
                        </>
                      ) : (
                        'Carregar mais'
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <p className="text-gray-600 dark:text-gray-300 text-center py-8">
                Nenhuma música encontrada nesta categoria.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryDetailPage;