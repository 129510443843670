import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';

const SettingsPage = () => {
  const { currentUser, logout } = useAuth();
  const { theme, toggleTheme } = useTheme();
  
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  
  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    newFollowers: true,
    comments: true,
    likes: true,
    directMessages: true,
    marketingEmails: false
  });
  
  const [privacySettings, setPrivacySettings] = useState({
    profileVisibility: 'public',
    showActivity: true,
    allowMessages: true
  });
  
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [activeTab, setActiveTab] = useState('account');
  
  // Manipular mudanças nos campos de senha
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  // Manipular mudanças nas configurações de notificação
  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
    setNotificationSettings(prev => ({
      ...prev,
      [name]: checked
    }));
  };
  
  // Manipular mudanças nas configurações de privacidade
  const handlePrivacyChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPrivacySettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  // Enviar formulário de alteração de senha
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    // Validar se as senhas coincidem
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setMessage({
        type: 'error',
        text: 'As senhas não coincidem.'
      });
      return;
    }
    
    setLoading(true);
    setMessage({ type: '', text: '' });
    
    try {
      // Simulando uma chamada à API para alterar a senha
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setMessage({
        type: 'success',
        text: 'Senha alterada com sucesso!'
      });
      
      // Limpar campos
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (error) {
      console.error('Erro ao alterar senha:', error);
      setMessage({
        type: 'error',
        text: 'Erro ao alterar senha. Por favor, tente novamente.'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Salvar configurações de notificação
  const handleNotificationSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });
    
    try {
      // Simulando uma chamada à API para salvar configurações
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setMessage({
        type: 'success',
        text: 'Configurações de notificação salvas com sucesso!'
      });
    } catch (error) {
      console.error('Erro ao salvar configurações:', error);
      setMessage({
        type: 'error',
        text: 'Erro ao salvar configurações. Por favor, tente novamente.'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Salvar configurações de privacidade
  const handlePrivacySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });
    
    try {
      // Simulando uma chamada à API para salvar configurações
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setMessage({
        type: 'success',
        text: 'Configurações de privacidade salvas com sucesso!'
      });
    } catch (error) {
      console.error('Erro ao salvar configurações:', error);
      setMessage({
        type: 'error',
        text: 'Erro ao salvar configurações. Por favor, tente novamente.'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Desativar conta
  const handleDeactivateAccount = async () => {
    if (window.confirm('Tem certeza que deseja desativar sua conta? Esta ação não pode ser desfeita.')) {
      try {
        // Simulando uma chamada à API para desativar a conta
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Fazer logout após desativar a conta
        logout();
      } catch (error) {
        console.error('Erro ao desativar conta:', error);
        setMessage({
          type: 'error',
          text: 'Erro ao desativar conta. Por favor, tente novamente.'
        });
      }
    }
  };
  
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Configurações
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Gerencie as configurações da sua conta.
        </p>
      </div>
      
      {message.text && (
        <div className={`mb-6 p-4 rounded-lg ${
          message.type === 'success' 
            ? 'bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300' 
            : 'bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-300'
        }`}>
          {message.text}
        </div>
      )}
      
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
        {/* Abas de navegação */}
        <div className="flex border-b border-gray-200 dark:border-dark-600">
          <button
            className={`px-6 py-3 text-sm font-medium ${
              activeTab === 'account'
                ? 'border-b-2 border-primary-500 text-primary-600 dark:text-primary-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('account')}
          >
            Conta
          </button>
          <button
            className={`px-6 py-3 text-sm font-medium ${
              activeTab === 'password'
                ? 'border-b-2 border-primary-500 text-primary-600 dark:text-primary-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('password')}
          >
            Senha
          </button>
          <button
            className={`px-6 py-3 text-sm font-medium ${
              activeTab === 'notifications'
                ? 'border-b-2 border-primary-500 text-primary-600 dark:text-primary-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('notifications')}
          >
            Notificações
          </button>
          <button
            className={`px-6 py-3 text-sm font-medium ${
              activeTab === 'privacy'
                ? 'border-b-2 border-primary-500 text-primary-600 dark:text-primary-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
            onClick={() => setActiveTab('privacy')}
          >
            Privacidade
          </button>
        </div>
        
        {/* Conteúdo das abas */}
        <div className="p-6">
          {/* Renderização condicional das abas */}
          {activeTab === 'account' && <AccountTab currentUser={currentUser} theme={theme} toggleTheme={toggleTheme} handleDeactivateAccount={handleDeactivateAccount} />}
          {activeTab === 'password' && <PasswordTab passwordData={passwordData} handlePasswordChange={handlePasswordChange} handlePasswordSubmit={handlePasswordSubmit} loading={loading} />}
          {activeTab === 'notifications' && <NotificationsTab notificationSettings={notificationSettings} handleNotificationChange={handleNotificationChange} handleNotificationSubmit={handleNotificationSubmit} loading={loading} />}
          {activeTab === 'privacy' && <PrivacyTab privacySettings={privacySettings} handlePrivacyChange={handlePrivacyChange} handlePrivacySubmit={handlePrivacySubmit} loading={loading} />}
        </div>
      </div>
    </div>
  );
};

// Componente da aba Conta
const AccountTab = ({ currentUser, theme, toggleTheme, handleDeactivateAccount }) => (
  <div>
    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Configurações da Conta</h2>
    
    {/* Tema */}
    <div className="mb-6">
      <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">Tema</h3>
      <div className="flex items-center">
        <span className="mr-3 text-sm text-gray-600 dark:text-gray-400">Claro</span>
        <button
          onClick={toggleTheme}
          className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ${
            theme === 'dark' ? 'bg-primary-600' : 'bg-gray-200 dark:bg-dark-600'
          }`}
        >
          <span
            className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
              theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
            }`}
          />
        </button>
        <span className="ml-3 text-sm text-gray-600 dark:text-gray-400">Escuro</span>
      </div>
    </div>
    
    {/* Idioma */}
    <div className="mb-6">
      <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">Idioma</h3>
      <select
        className="w-full md:w-64 px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
      >
        <option value="pt-BR">Português (Brasil)</option>
        <option value="en-US">English (US)</option>
        <option value="es">Español</option>
      </select>
    </div>
    
    {/* Plano */}
    <div className="mb-6">
      <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2">Plano Atual</h3>
      <div className="p-4 bg-gray-50 dark:bg-dark-700 rounded-lg">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-medium text-gray-800 dark:text-white">
              {currentUser?.premium ? 'Premium' : 'Gratuito'}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {currentUser?.premium 
                ? 'Você tem acesso a todos os recursos premium.' 
                : 'Atualize para o plano premium para acessar mais recursos.'}
            </p>
          </div>
          {!currentUser?.premium && (
            <button className="px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors">
              Atualizar
            </button>
          )}
        </div>
      </div>
    </div>
    
    {/* Desativar conta */}
    <div className="border-t border-gray-200 dark:border-dark-600 pt-6 mt-6">
      <h3 className="text-lg font-medium text-red-600 dark:text-red-400 mb-2">Zona de Perigo</h3>
      <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
        Ao desativar sua conta, todos os seus dados serão removidos permanentemente.
        Esta ação não pode ser desfeita.
      </p>
      <button
        onClick={handleDeactivateAccount}
        className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
      >
        Desativar Conta
      </button>
    </div>
  </div>
);

// Componente da aba Senha
const PasswordTab = ({ passwordData, handlePasswordChange, handlePasswordSubmit, loading }) => (
  <div>
    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Alterar Senha</h2>
    <form onSubmit={handlePasswordSubmit}>
      <div className="mb-4">
        <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Senha Atual
        </label>
        <input
          type="password"
          id="currentPassword"
          name="currentPassword"
          value={passwordData.currentPassword}
          onChange={handlePasswordChange}
          className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
          required
        />
      </div>
      
      <div className="mb-4">
        <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Nova Senha
        </label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          value={passwordData.newPassword}
          onChange={handlePasswordChange}
          className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
          required
          minLength="8"
        />
        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
          A senha deve ter pelo menos 8 caracteres.
        </p>
      </div>
      
      <div className="mb-6">
        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Confirmar Nova Senha
        </label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={passwordData.confirmPassword}
          onChange={handlePasswordChange}
          className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
          required
        />
      </div>
      
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={loading}
          className={`px-6 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors ${
            loading ? 'opacity-70 cursor-not-allowed' : ''
          }`}
        >
          {loading ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Salvando...
            </span>
          ) : 'Alterar Senha'}
        </button>
      </div>
    </form>
  </div>
);

// Componente da aba Notificações
const NotificationsTab = ({ notificationSettings, handleNotificationChange, handleNotificationSubmit, loading }) => (
  <div>
    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Configurações de Notificação</h2>
    <form onSubmit={handleNotificationSubmit}>
      <div className="space-y-4 mb-6">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Notificações por Email</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Receba notificações por email sobre atividades importantes.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="emailNotifications"
              name="emailNotifications"
              checked={notificationSettings.emailNotifications}
              onChange={handleNotificationChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
          </div>
        </div>
        
        <div className="flex items-center justify-between pl-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Novos Seguidores</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Receba notificações quando alguém começar a seguir você.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="newFollowers"
              name="newFollowers"
              checked={notificationSettings.newFollowers}
              onChange={handleNotificationChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
              disabled={!notificationSettings.emailNotifications}
            />
          </div>
        </div>
        
        <div className="flex items-center justify-between pl-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Comentários</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Receba notificações quando alguém comentar em suas músicas ou álbuns.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="comments"
              name="comments"
              checked={notificationSettings.comments}
              onChange={handleNotificationChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
              disabled={!notificationSettings.emailNotifications}
            />
          </div>
        </div>
        
        <div className="flex items-center justify-between pl-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Curtidas</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Receba notificações quando alguém curtir suas músicas ou álbuns.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="likes"
              name="likes"
              checked={notificationSettings.likes}
              onChange={handleNotificationChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
              disabled={!notificationSettings.emailNotifications}
            />
          </div>
        </div>
        
        <div className="flex items-center justify-between pl-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Mensagens Diretas</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Receba notificações quando alguém enviar uma mensagem direta para você.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="directMessages"
              name="directMessages"
              checked={notificationSettings.directMessages}
              onChange={handleNotificationChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
              disabled={!notificationSettings.emailNotifications}
            />
          </div>
        </div>
        
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Emails de Marketing</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Receba emails sobre novidades, promoções e atualizações da plataforma.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="marketingEmails"
              name="marketingEmails"
              checked={notificationSettings.marketingEmails}
              onChange={handleNotificationChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
          </div>
        </div>
      </div>
      
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={loading}
          className={`px-6 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors ${
            loading ? 'opacity-70 cursor-not-allowed' : ''
          }`}
        >
          {loading ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Salvando...
            </span>
          ) : 'Salvar Configurações'}
        </button>
      </div>
    </form>
  </div>
);

// Componente da aba Privacidade
const PrivacyTab = ({ privacySettings, handlePrivacyChange, handlePrivacySubmit, loading }) => (
  <div>
    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Configurações de Privacidade</h2>
    <form onSubmit={handlePrivacySubmit}>
      <div className="space-y-6 mb-6">
        <div>
          <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Visibilidade do Perfil</h3>
          <div className="space-y-2">
            <div className="flex items-center">
              <input
                type="radio"
                id="public"
                name="profileVisibility"
                value="public"
                checked={privacySettings.profileVisibility === 'public'}
                onChange={handlePrivacyChange}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300"
              />
              <label htmlFor="public" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                Público - Qualquer pessoa pode ver seu perfil
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="followers"
                name="profileVisibility"
                value="followers"
                checked={privacySettings.profileVisibility === 'followers'}
                onChange={handlePrivacyChange}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300"
              />
              <label htmlFor="followers" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                Seguidores - Apenas seus seguidores podem ver seu perfil
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="private"
                name="profileVisibility"
                value="private"
                checked={privacySettings.profileVisibility === 'private'}
                onChange={handlePrivacyChange}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300"
              />
              <label htmlFor="private" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                Privado - Apenas você pode ver seu perfil
              </label>
            </div>
          </div>
        </div>
        
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Mostrar Atividade</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Permitir que outros usuários vejam sua atividade recente (músicas ouvidas, curtidas, etc).
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="showActivity"
              name="showActivity"
              checked={privacySettings.showActivity}
              onChange={handlePrivacyChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
          </div>
        </div>
        
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Permitir Mensagens</h3>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Permitir que outros usuários enviem mensagens diretas para você.
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="allowMessages"
              name="allowMessages"
              checked={privacySettings.allowMessages}
              onChange={handlePrivacyChange}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
          </div>
        </div>
      </div>
      
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={loading}
          className={`px-6 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors ${
            loading ? 'opacity-70 cursor-not-allowed' : ''
          }`}
        >
          {loading ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Salvando...
            </span>
          ) : 'Salvar Configurações'}
        </button>
      </div>
    </form>
  </div>
);

export default SettingsPage;
