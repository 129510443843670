import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

/**
 * Componente para proteger rotas que requerem autenticação.
 * Redireciona para a página de login se o usuário não estiver autenticado.
 */
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  // Mostra um indicador de carregamento enquanto verifica a autenticação
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  // Redireciona para a página de login se não estiver autenticado
  if (!isAuthenticated) {
    // Salva a localização atual para redirecionar de volta após o login
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  // Renderiza o conteúdo protegido se estiver autenticado
  return children;
};

export default ProtectedRoute;