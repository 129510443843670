import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import api from '../services/api';
import AlbumCard from '../components/albums/AlbumCard';
import ArtistCard from '../components/artists/ArtistCard';
import SongCard from '../components/songs/SongCard';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';

const HomePage = () => {
  // Estado para controlar qual aba está ativa para cada categoria
  const [activeTabs, setActiveTabs] = useState({});
  
  // Função para alternar entre abas
  const toggleTab = (categoryId, tabIndex) => {
    setActiveTabs(prev => ({
      ...prev,
      [categoryId]: tabIndex
    }));
  };
  // Busca os destaques da semana
  const { data: featuredSongs, isLoading: loadingFeatured } = useQuery('featuredSongs', async () => {
    const response = await api.get('/featured/');
    return response.data;
  }, {
    staleTime: 1000 * 60 * 5, // 5 minutos
  });

  // Busca os álbuns recentes
  const { data: recentAlbums, isLoading: loadingAlbums } = useQuery('recentAlbums', async () => {
    const response = await api.get('/recent-albums/');
    return response.data;
  }, {
    staleTime: 1000 * 60 * 5, // 5 minutos
  });

  // Busca os top álbuns do mês
  const { data: topAlbumsMonth, isLoading: loadingTopAlbums } = useQuery('topAlbumsMonth', async () => {
    const response = await api.get('/top-albums-month/', {
      params: {
        limit: 5,
        enabled: 1
      }
    });
    return response.data;
  }, {
    staleTime: 1000 * 60 * 5, // 5 minutos
  });

  // Busca os artistas mais populares
  const { data: topArtists, isLoading: loadingArtists } = useQuery('topArtists', async () => {
    const response = await api.get('/top-artists/', {
      params: {
        limit: 5,
        enabled: 1
      }
    });
    return response.data;
  }, {
    staleTime: 1000 * 60 * 5, // 5 minutos
  });

  // Busca as músicas populares
  const { data: popularSongs, isLoading: loadingSongs } = useQuery('popularSongs', async () => {
    const response = await api.get('/popular-songs/');
    return response.data;
  }, {
    staleTime: 1000 * 60 * 5, // 5 minutos
  });

  // Busca as categorias
  const { data: categories, isLoading: loadingCategories } = useQuery('categories', async () => {
    const response = await api.get('/categories/');
    return response.data;
  }, {
    staleTime: 1000 * 60 * 5, // 5 minutos
  });

  // Busca álbuns por categoria
  const { data: albumsByCategory, isLoading: loadingAlbumsByCategory } = useQuery(
    'albumsByCategory',
    async () => {
      if (!categories) return {};
      
      const categoryAlbums = {};
      
      // Usa todas as categorias disponíveis
      for (const category of categories) {
        const response = await api.get('/recent-albums/', {
          params: {
            limite: 5, // Limitado a 5 álbuns por categoria conforme solicitado
            categoryid: category.id
          }
        });
        categoryAlbums[category.id] = {
          name: category.nome,
          albums: response.data
        };
      }
      
      return categoryAlbums;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutos
      enabled: !!categories, // Só executa quando as categorias estiverem carregadas
    }
  );

  // Busca músicas por categoria
  const { data: songsByCategory, isLoading: loadingSongsByCategory } = useQuery(
    'songsByCategory',
    async () => {
      if (!categories) return {};
      
      const categorySongs = {};
      
      // Usa todas as categorias disponíveis
      for (const category of categories) {
        const response = await api.get('/songs/', {
          params: {
            limit: 5, // Limitado a 5 músicas por categoria
            category_id: category.id
          }
        });
        categorySongs[category.id] = {
          name: category.nome,
          songs: response.data
        };
      }
      
      return categorySongs;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutos
      enabled: !!categories, // Só executa quando as categorias estiverem carregadas
    }
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-primary-600 dark:text-primary-400 mb-4">
          Bem-vindo ao Balada G4
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-300">
          A maior plataforma de músicas eletrônicas, funk, DJs, MCs e som automotivo do Brasil
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Seção de Destaques */}
        <div className="col-span-full mb-8">
          <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2">
            Destaques da Semana
          </h2>
          <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {loadingFeatured ? (
              <p className="text-gray-600 dark:text-gray-300">Carregando destaques...</p>
            ) : featuredSongs && featuredSongs.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                {featuredSongs.map((song) => (
                  <SongCard key={song.id} song={song} />
                ))}
              </div>
            ) : (
              <p className="text-gray-600 dark:text-gray-300">
                Nenhum destaque encontrado.
              </p>
            )}
          </div>
        </div>

        {/* Seção de Top Álbuns do Mês */}
        <div className="col-span-full mb-8">
          <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2">
            Top Álbuns do Mês
          </h2>
          <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {loadingTopAlbums ? (
              <p className="text-gray-600 dark:text-gray-300">Carregando top álbuns...</p>
            ) : topAlbumsMonth && topAlbumsMonth.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                {topAlbumsMonth.map((album) => (
                  <div key={album.id} className="relative">
                    <div className="absolute top-0 left-0 bg-primary-600 text-white font-bold rounded-tl-lg rounded-br-lg px-2 py-1 z-10">
                      #{album.ranking}
                    </div>
                    <AlbumCard album={album} />
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600 dark:text-gray-300">
                Nenhum álbum encontrado.
              </p>
            )}
          </div>
        </div>

        {/* Seção de Álbuns Recentes */}
        <div className="col-span-full lg:col-span-2 mb-8">
          <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2">
            Álbuns Recentes
          </h2>
          <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {loadingAlbums ? (
              <p className="text-gray-600 dark:text-gray-300">Carregando álbuns...</p>
            ) : recentAlbums && recentAlbums.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {recentAlbums.map((album) => (
                  <AlbumCard key={album.idcd} album={album} />
                ))}
              </div>
            ) : (
              <p className="text-gray-600 dark:text-gray-300">
                Nenhum álbum encontrado.
              </p>
            )}
          </div>
        </div>

        {/* Seção de Top Artistas */}
        <div className="col-span-full lg:col-span-1 mb-8">
          <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2">
            Top Artistas
          </h2>
          <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {loadingArtists ? (
              <p className="text-gray-600 dark:text-gray-300">Carregando artistas...</p>
            ) : topArtists && topArtists.length > 0 ? (
              <div className="grid grid-cols-1 gap-4">
                {topArtists.map((artist) => (
                  <ArtistCard key={artist.id || artist.ranking} artist={artist} />
                ))}
              </div>
            ) : (
              <p className="text-gray-600 dark:text-gray-300">
                Nenhum artista encontrado.
              </p>
            )}
          </div>
        </div>

        {/* Seção de Músicas Populares */}
        <div className="col-span-full mb-8">
          <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2">
            Músicas Populares
          </h2>
          <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
            {loadingSongs ? (
              <p className="text-gray-600 dark:text-gray-300">Carregando músicas...</p>
            ) : popularSongs && popularSongs.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {popularSongs.map((song) => (
                  <SongCard key={song.id} song={song} />
                ))}
              </div>
            ) : (
              <p className="text-gray-600 dark:text-gray-300">
                Nenhuma música encontrada.
              </p>
            )}
          </div>
        </div>

        {/* Seção de Conteúdo por Categoria */}
        <div className="col-span-full mb-8">
          <h2 className="text-2xl font-bold mb-4 border-b border-gray-200 dark:border-dark-700 pb-2">
            Conteúdo por Categoria
          </h2>
          
          {loadingCategories || loadingAlbumsByCategory || loadingSongsByCategory ? (
            <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6 flex justify-center">
              <LoadingSpinner size="lg" />
            </div>
          ) : categories && categories.length > 0 ? (
            categories.map((category) => (
              <div key={category.id} className="mb-8">
                <div className="flex justify-between items-center mb-4 border-b border-gray-200 dark:border-dark-700 pb-2">
                  <h3 className="text-xl font-bold">
                    {category.nome}
                  </h3>
                  <Link
                    to={`/category/${category.id}`}
                    className="text-primary-600 dark:text-primary-400 hover:underline"
                  >
                    Ver mais
                  </Link>
                </div>
                
                <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
                  {/* Abas personalizadas */}
                  <div className="flex border-b border-gray-200 dark:border-dark-700 mb-4">
                    <button
                      className={`px-4 py-2 font-medium cursor-pointer border-b-2 ${
                        !activeTabs[category.id] || activeTabs[category.id] === 0
                          ? 'border-primary-600 text-primary-600'
                          : 'border-transparent hover:text-primary-600 hover:border-primary-600'
                      } focus:outline-none`}
                      onClick={() => toggleTab(category.id, 0)}
                    >
                      Álbuns
                    </button>
                    <button
                      className={`px-4 py-2 font-medium cursor-pointer border-b-2 ${
                        activeTabs[category.id] === 1
                          ? 'border-primary-600 text-primary-600'
                          : 'border-transparent hover:text-primary-600 hover:border-primary-600'
                      } focus:outline-none`}
                      onClick={() => toggleTab(category.id, 1)}
                    >
                      Músicas
                    </button>
                  </div>

                  {/* Conteúdo da aba selecionada */}
                  {(!activeTabs[category.id] || activeTabs[category.id] === 0) && (
                    <>
                      {albumsByCategory &&
                       albumsByCategory[category.id] &&
                       albumsByCategory[category.id].albums &&
                       albumsByCategory[category.id].albums.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                          {albumsByCategory[category.id].albums.slice(0, 5).map((album) => (
                            <AlbumCard key={album.idcd} album={album} />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-600 dark:text-gray-300 text-center py-4">
                          Nenhum álbum encontrado nesta categoria.
                        </p>
                      )}
                    </>
                  )}

                  {activeTabs[category.id] === 1 && (
                    <>
                      {songsByCategory &&
                       songsByCategory[category.id] &&
                       songsByCategory[category.id].songs &&
                       songsByCategory[category.id].songs.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                          {songsByCategory[category.id].songs.slice(0, 5).map((song) => (
                            <SongCard key={song.id} song={song} />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-600 dark:text-gray-300 text-center py-4">
                          Nenhuma música encontrada nesta categoria.
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
              <p className="text-gray-600 dark:text-gray-300 text-center">
                Nenhuma categoria encontrada.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;