import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Componente para exibir um card de música
 * @param {Object} song - Objeto contendo os dados da música
 */
const SongCard = ({ song }) => {
  // Função para obter a URL da imagem do DJ ou uma imagem padrão
  const getDjImageUrl = () => {
    if (song.artist && song.artist.profile_image) {
      return song.artist.profile_image.startsWith('http')
        ? song.artist.profile_image
        : `https://imagens.baladag4.com.br/crop?width=100&height=100&&file=${song.artist.profile_image.replace('uploads/', '')}`;
    }
    return '/placeholder-dj.jpg';
  };

  // Função para obter o nome do DJ
  const getDjName = () => {
    return song.artist ? song.artist.username : song.artist_name || "Artista Desconhecido";
  };

  return (
    <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden transition-transform hover:scale-105">
      <Link to={`/songs/${song.id}`}>
        <div className="relative">
          {/* Imagem do DJ */}
          <div className="h-32 bg-gray-200 dark:bg-dark-700 flex items-center justify-center">
            {song.artist && song.artist.profile_image ? (
              <img
                src={getDjImageUrl()}
                alt={`DJ ${getDjName()}`}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex items-center justify-center w-full h-full">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white truncate">
            {song.title}
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-300 mt-1 truncate">
            DJ: {getDjName()}
          </p>
          <div className="flex items-center justify-between mt-2">
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {song.plays || 0} reproduções
            </span>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {song.downloads || 0} downloads
            </span>
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
            {song.created_at ? new Date(song.created_at).toLocaleDateString('pt-BR') : ""}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SongCard;