import React from 'react';
import { usePlayer } from '../../context/PlayerContext';

const MusicPlayer = () => {
  const {
    currentTrack,
    queue,
    isPlaying,
    currentTime,
    duration,
    volume,
    isExpanded,
    showVolumeControl,
    audioRef,
    bufferedProgress,
    isLoading,
    formatTime,
    togglePlay,
    handleProgressChange,
    handleVolumeChange,
    playNextTrack,
    playPreviousTrack,
    setIsExpanded,
    setShowVolumeControl
  } = usePlayer();

  // Log para depuração
  React.useEffect(() => {
    if (currentTrack) {
      console.log('MusicPlayer - Música atual:', currentTrack);
      console.log('MusicPlayer - URL do arquivo:', currentTrack.file_url);
      console.log('MusicPlayer - Duração:', duration);
      console.log('MusicPlayer - Progresso de buffer:', bufferedProgress);
    }
  }, [currentTrack, duration, bufferedProgress]);

  // Se não houver música atual, não exibe o player
  if (!currentTrack) {
    return null;
  }

  return (
    <div className={`fixed bottom-0 left-0 right-0 bg-white dark:bg-dark-800 border-t border-gray-200 dark:border-dark-700 shadow-lg transition-all duration-300 z-50 ${isExpanded ? 'h-64' : 'h-20'}`}>
      {/* Player de áudio (invisível) */}
      <audio
        ref={audioRef}
        src={currentTrack?.file_url || ''}
        preload="auto"
        onLoadedMetadata={() => {
          console.log("Metadados carregados no elemento de áudio");
          console.log("Duração:", audioRef.current?.duration);
        }}
        onProgress={() => {
          try {
            if (audioRef.current && audioRef.current.buffered && audioRef.current.buffered.length > 0) {
              const bufferedEnd = audioRef.current.buffered.end(audioRef.current.buffered.length - 1);
              const duration = audioRef.current.duration;
              if (duration > 0) {
                const progress = (bufferedEnd / duration) * 100;
                console.log(`Buffer: ${progress.toFixed(2)}%`);
              }
            }
          } catch (error) {
            console.log("Erro ao processar progresso do buffer:", error);
          }
        }}
        onError={(e) => console.error("Erro ao carregar áudio:", e)}
        onEnded={() => console.log("Áudio finalizado")}
      />
      
      <div className="max-w-7xl mx-auto px-4 h-full flex flex-col">
        {/* Barra de progresso */}
        <div className="w-full h-1 bg-gray-200 dark:bg-dark-700 relative cursor-pointer group">
          <input
            type="range"
            min="0"
            max="100"
            value={(currentTime / duration) * 100 || 0}
            onChange={handleProgressChange}
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-10"
          />
          {/* Barra de buffer */}
          <div
            className="h-full bg-gray-400 dark:bg-gray-600 absolute top-0 left-0 transition-all"
            style={{ width: `${bufferedProgress || 0}%` }}
          ></div>
          {/* Barra de progresso de reprodução */}
          <div
            className="h-full bg-primary-500 group-hover:bg-primary-400 transition-all absolute top-0 left-0"
            style={{ width: `${(currentTime / duration) * 100 || 0}%` }}
          ></div>
          {/* Indicador de carregamento */}
          {isLoading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <div className="w-full h-full bg-primary-200 dark:bg-primary-900 opacity-30 animate-pulse"></div>
            </div>
          )}
        </div>
        
        {/* Controles principais */}
        <div className="flex items-center justify-between h-20">
          {/* Informações da música */}
          <div className="flex items-center space-x-4 w-1/3">
            <div className="h-12 w-12 bg-gray-300 dark:bg-dark-600 rounded overflow-hidden flex-shrink-0">
              {currentTrack?.image_url ? (
                <img
                  src={currentTrack.image_url}
                  alt={currentTrack.title}
                  className="h-full w-full object-cover"
                />
              ) : (
                <div className="h-full w-full flex items-center justify-center text-gray-500 dark:text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                  </svg>
                </div>
              )}
            </div>
            <div className="truncate">
              <h3 className="text-sm font-medium text-gray-900 dark:text-white truncate">
                {currentTrack?.title || 'Título desconhecido'}
              </h3>
              <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                {currentTrack?.artist || 'Artista desconhecido'}
              </p>
            </div>
          </div>
          
          {/* Controles de reprodução */}
          <div className="flex items-center space-x-4">
            <button
              onClick={playPreviousTrack}
              className="p-2 text-gray-500 hover:text-primary-600 dark:text-gray-400 dark:hover:text-primary-400 focus:outline-none"
              aria-label="Música anterior"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z" />
              </svg>
            </button>
            <button
              onClick={togglePlay}
              className="p-3 bg-primary-600 hover:bg-primary-700 text-white rounded-full focus:outline-none"
              aria-label={isPlaying ? 'Pausar' : 'Reproduzir'}
            >
              {isPlaying ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}
            </button>
            <button
              onClick={playNextTrack}
              className="p-2 text-gray-500 hover:text-primary-600 dark:text-gray-400 dark:hover:text-primary-400 focus:outline-none"
              aria-label="Próxima música"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z" />
              </svg>
            </button>
          </div>
          
          {/* Controles adicionais */}
          <div className="flex items-center space-x-4 w-1/3 justify-end">
            {/* Tempo e status de download */}
            <div className="hidden sm:block text-xs text-gray-500 dark:text-gray-400">
              <span>{formatTime(currentTime)}</span>
              <span> / </span>
              <span>{formatTime(duration)}</span>
              {isLoading && (
                <span className="ml-2 text-primary-500 dark:text-primary-400 animate-pulse">
                  Carregando...
                </span>
              )}
              {!isLoading && (
                <span className="ml-2 text-gray-400 dark:text-gray-500">
                  ({Math.round(bufferedProgress || 0)}% baixado)
                </span>
              )}
            </div>
            
            {/* Controle de volume */}
            <div className="relative">
              <button
                onClick={() => setShowVolumeControl(!showVolumeControl)}
                className="p-2 text-gray-500 hover:text-primary-600 dark:text-gray-400 dark:hover:text-primary-400 focus:outline-none"
                aria-label="Volume"
              >
                {volume === 0 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                  </svg>
                ) : volume < 0.5 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m3-10.036a9 9 0 010 13" />
                  </svg>
                )}
              </button>
              
              {showVolumeControl && (
                <div className="absolute bottom-full right-0 mb-2 p-2 bg-white dark:bg-dark-700 rounded shadow-lg">
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={handleVolumeChange}
                    className="w-24 h-2 bg-gray-200 dark:bg-dark-600 rounded-full appearance-none cursor-pointer"
                    style={{
                      backgroundImage: `linear-gradient(to right, var(--tw-color-primary-500) 0%, var(--tw-color-primary-500) ${volume * 100}%, var(--tw-color-gray-200) ${volume * 100}%, var(--tw-color-gray-200) 100%)`
                    }}
                  />
                </div>
              )}
            </div>
            
            {/* Botão de expandir/recolher */}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="p-2 text-gray-500 hover:text-primary-600 dark:text-gray-400 dark:hover:text-primary-400 focus:outline-none"
              aria-label={isExpanded ? 'Recolher player' : 'Expandir player'}
            >
              {isExpanded ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              )}
            </button>
          </div>
        </div>
        
        {/* Conteúdo expandido */}
        {isExpanded && (
          <div className="flex-1 overflow-hidden">
            <div className="h-full flex">
              {/* Capa do álbum */}
              <div className="w-1/3 p-4 flex items-center justify-center">
                <div className="w-32 h-32 bg-gray-300 dark:bg-dark-600 rounded-lg overflow-hidden shadow-lg">
                  {currentTrack?.image_url ? (
                    <img
                      src={currentTrack.image_url}
                      alt={currentTrack.title}
                      className="h-full w-full object-cover"
                    />
                  ) : (
                    <div className="h-full w-full flex items-center justify-center text-gray-500 dark:text-gray-400">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Informações detalhadas */}
              <div className="w-1/3 p-4">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  {currentTrack?.title || 'Título desconhecido'}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {currentTrack?.artist || 'Artista desconhecido'}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                  {currentTrack?.album || 'Álbum desconhecido'}
                </p>
                
                {/* Botões de ação */}
                <div className="mt-4 flex space-x-2">
                  <button className="px-3 py-1 text-xs bg-primary-600 hover:bg-primary-700 text-white rounded-full focus:outline-none">
                    Adicionar à playlist
                  </button>
                  <button className="px-3 py-1 text-xs bg-gray-200 hover:bg-gray-300 dark:bg-dark-700 dark:hover:bg-dark-600 text-gray-700 dark:text-gray-300 rounded-full focus:outline-none">
                    Compartilhar
                  </button>
                </div>
              </div>
              
              {/* Fila de reprodução */}
              <div className="w-1/3 p-4">
                <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                  Próximas na fila ({queue.length})
                </h3>
                <div className="overflow-y-auto h-[calc(100%-2rem)] pr-2">
                  {queue.length > 0 ? (
                    <ul className="space-y-2">
                      {queue.map((track, index) => (
                        <li key={index} className="flex items-center space-x-2 text-sm">
                          <div className="h-8 w-8 bg-gray-300 dark:bg-dark-600 rounded overflow-hidden flex-shrink-0">
                            {track?.image_url ? (
                              <img
                                src={track.image_url}
                                alt={track.title}
                                className="h-full w-full object-cover"
                              />
                            ) : (
                              <div className="h-full w-full flex items-center justify-center text-gray-500 dark:text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                                </svg>
                              </div>
                            )}
                          </div>
                          <div className="truncate flex-1">
                            <p className="truncate text-gray-900 dark:text-white">
                              {track?.title || 'Título desconhecido'}
                            </p>
                            <p className="truncate text-xs text-gray-500 dark:text-gray-400">
                              {track?.artist || 'Artista desconhecido'}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Nenhuma música na fila
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicPlayer;