import React, { useState, useEffect } from 'react';
import HomePage from './HomePage';
import GenericPage from './GenericPage';
import CategoryDetailPage from './CategoryDetailPage';
import DashboardPageComponent from './DashboardPage';
import ProfilePageComponent from './ProfilePage';
import SettingsPageComponent from './SettingsPage';
import MyAlbumsPageComponent from './MyAlbumsPage';
import MySongsPageComponent from './MySongsPage';
import StatsPageComponent from './StatsPage';
import FollowersPageComponent from './FollowersPage';
import AlbumList from '../components/albums/AlbumList';
import { CategoryAlbumList, CategorySongList, CategoryArtistList } from '../components/lists';
import AlbumDetail from '../components/albums/AlbumDetail';
import ArtistList from '../components/artists/ArtistList';
import ArtistDetail from '../components/artists/ArtistDetail';
import SongList from '../components/songs/SongList';
import SongDetail from '../components/songs/SongDetail';
import SearchForm from '../components/search/SearchForm';
import AboutContent from '../components/about/AboutContent';
import ContactForm from '../components/contact/ContactForm';
import LoginForm from '../components/auth/LoginForm';
import RegisterForm from '../components/auth/RegisterForm';
import api from '../services/api';

// Páginas públicas
export { HomePage, CategoryDetailPage };
export const AlbumsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('recent');
  const [categoryId, setCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);

  // Busca as categorias
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };
    
    fetchCategories();
  }, []);

  // Função para limpar filtros
  const clearFilters = () => {
    setSearchTerm('');
    setSortOrder('recent');
    setCategoryId(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Álbuns
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Explore nossa coleção de álbuns de música eletrônica, funk e som automotivo.
        </p>
      </div>
      
      {/* Filtros funcionais */}
      <div className="mb-6 p-4 bg-white dark:bg-dark-800 rounded-lg shadow-md">
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex-grow">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar álbuns..."
              className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            />
          </div>
          <div>
            <select
              value={categoryId || ''}
              onChange={(e) => setCategoryId(e.target.value ? Number(e.target.value) : null)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="">Todos os gêneros</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="recent">Mais recentes</option>
              <option value="popular">Mais populares</option>
              <option value="az">A-Z</option>
            </select>
          </div>
          <div>
            <button
              onClick={clearFilters}
              className="px-4 py-2 bg-gray-200 dark:bg-dark-600 hover:bg-gray-300 dark:hover:bg-dark-500 text-gray-800 dark:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors"
            >
              Limpar filtros
            </button>
          </div>
        </div>
      </div>
      
      {/* Lista de álbuns com os filtros aplicados */}
      <CategoryAlbumList
        searchTerm={searchTerm}
        sortOrder={sortOrder}
        categoryId={categoryId}
        queryKey="albumsPage"
      />
    </div>
  );
};
export const AlbumDetailPage = () => (
  <div className="container mx-auto px-4 py-8">
    <AlbumDetail />
  </div>
);
export const SongsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [sortBy, setSortBy] = useState('recent'); // Definindo "recent" como padrão para mostrar as mais recentes primeiro
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [categories, setCategories] = useState([]);

  // Efeito para debounce da busca
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Busca as categorias
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };
    
    fetchCategories();
  }, []);

  // Função para limpar filtros
  const clearFilters = () => {
    setSearchTerm('');
    setCategoryId('');
    setSortBy('recent'); // Mantém a ordenação por mais recentes ao limpar os filtros
  };

  // Mapeia os valores do select para os valores esperados pela API
  const getSortByValue = () => {
    switch (sortBy) {
      case 'popular':
        return 'views';
      case 'recent':
        return 'date';
      case 'az':
        return 'title';
      default:
        return '';
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Músicas
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Explore nossa coleção de músicas eletrônicas, funk e som automotivo.
        </p>
      </div>
      
      {/* Filtros funcionais */}
      <div className="mb-6 p-4 bg-white dark:bg-dark-800 rounded-lg shadow-md">
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex-grow">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar músicas..."
              className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            />
          </div>
          <div>
            <select
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="">Todas as categorias</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="recent">Mais recentes</option>
              <option value="popular">Mais visualizadas</option>
              <option value="az">Título (A-Z)</option>
            </select>
          </div>
          {(searchTerm || categoryId || sortBy) && (
            <div>
              <button
                onClick={clearFilters}
                className="px-4 py-2 bg-gray-200 dark:bg-dark-600 hover:bg-gray-300 dark:hover:bg-dark-500 text-gray-800 dark:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors"
              >
                Limpar filtros
              </button>
            </div>
          )}
        </div>
      </div>
      
      {/* Lista de músicas */}
      <CategorySongList
        searchTerm={debouncedSearch}
        categoryId={categoryId}
        sortBy={getSortByValue()}
        queryKey="songsPage"
      />
    </div>
  );
};
export const SongDetailPage = () => (
  <div className="container mx-auto px-4 py-8">
    <SongDetail />
  </div>
);
export const ArtistsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('popular');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  // Efeito para debounce da busca
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Função para limpar filtros
  const clearFilters = () => {
    setSearchTerm('');
    setSortBy('popular');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Artistas
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Conheça os DJs e MCs mais populares da plataforma.
        </p>
      </div>
      
      {/* Filtros funcionais */}
      <div className="mb-6 p-4 bg-white dark:bg-dark-800 rounded-lg shadow-md">
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex-grow">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar artistas..."
              className="w-full px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            />
          </div>
          <div>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-dark-700 dark:text-white"
            >
              <option value="popular">Mais populares</option>
              <option value="recent">Mais recentes</option>
              <option value="name">A-Z</option>
            </select>
          </div>
          {(searchTerm || sortBy !== 'popular') && (
            <div>
              <button
                onClick={clearFilters}
                className="px-4 py-2 bg-gray-200 dark:bg-dark-600 hover:bg-gray-300 dark:hover:bg-dark-500 text-gray-800 dark:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors"
              >
                Limpar filtros
              </button>
            </div>
          )}
        </div>
      </div>
      
      {/* Lista de artistas */}
      <CategoryArtistList
        searchTerm={debouncedSearch}
        sortBy={sortBy}
        queryKey="artistsPage"
      />
    </div>
  );
};
export const ArtistDetailPage = () => (
  <div className="container mx-auto px-4 py-8">
    <ArtistDetail />
  </div>
);
export const SearchPage = () => (
  <div className="container mx-auto px-4 py-8">
    <div className="mb-8">
      <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
        Busca
      </h1>
      <p className="text-lg text-gray-600 dark:text-gray-300">
        Encontre músicas, álbuns e artistas.
      </p>
    </div>
    
    <SearchForm />
  </div>
);

export const AboutPage = () => (
  <div className="container mx-auto px-4 py-8">
    <div className="mb-8">
      <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
        Sobre Nós
      </h1>
      <p className="text-lg text-gray-600 dark:text-gray-300">
        Conheça mais sobre a plataforma Balada G4.
      </p>
    </div>
    
    <AboutContent />
  </div>
);

export const ContactPage = () => (
  <div className="container mx-auto px-4 py-8">
    <div className="mb-8">
      <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
        Contato
      </h1>
      <p className="text-lg text-gray-600 dark:text-gray-300">
        Entre em contato conosco.
      </p>
    </div>
    
    <ContactForm />
  </div>
);

export const NotFoundPage = () => <GenericPage title="Página Não Encontrada" description="A página que você está procurando não existe." />;

// Páginas de autenticação
export const LoginPage = () => (
  <div className="container mx-auto px-4 py-8">
    <div className="mb-8">
      <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2 text-center">
        Login
      </h1>
      <p className="text-lg text-gray-600 dark:text-gray-300 text-center">
        Acesse sua conta.
      </p>
    </div>
    
    <LoginForm />
  </div>
);

export const RegisterPage = () => (
  <div className="container mx-auto px-4 py-8">
    <div className="mb-8">
      <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2 text-center">
        Cadastro
      </h1>
      <p className="text-lg text-gray-600 dark:text-gray-300 text-center">
        Crie uma nova conta.
      </p>
    </div>
    
    <RegisterForm />
  </div>
);
export const ForgotPasswordPage = () => <GenericPage title="Esqueci a Senha" description="Recupere sua senha." />;
export const ResetPasswordPage = () => <GenericPage title="Redefinir Senha" description="Defina uma nova senha para sua conta." />;

// Páginas protegidas
export const DashboardPage = DashboardPageComponent;
export const ProfilePage = ProfilePageComponent;
export const SettingsPage = SettingsPageComponent;
export const MyAlbumsPage = MyAlbumsPageComponent;
export const MySongsPage = MySongsPageComponent;
export const StatsPage = StatsPageComponent;
export const FollowersPage = FollowersPageComponent;

// Páginas protegidas ainda não implementadas
export const UploadAlbumPage = () => <GenericPage title="Enviar Álbum" description="Envie um novo álbum para a plataforma." />;
export const UploadSongPage = () => <GenericPage title="Enviar Música" description="Envie uma nova música para a plataforma." />;
export const PlaylistsPage = () => <GenericPage title="Minhas Playlists" description="Gerencie suas playlists." />;
export const PlaylistDetailPage = () => <GenericPage title="Detalhes da Playlist" description="Informações detalhadas sobre esta playlist." />;