import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '../../services/api';
import ArtistCard from './ArtistCard';
import LoadingSpinner from '../common/LoadingSpinner';

/**
 * Componente otimizado para exibir uma lista de artistas com botão de carregar mais
 * @param {number} limit - Número máximo de artistas a serem exibidos por página
 * @param {string} searchTerm - Termo de busca para filtrar artistas
 * @param {string} sortBy - Campo para ordenação (popular, recent, name)
 * @param {string|number} categoryId - ID da categoria para filtrar artistas
 * @param {string} queryKey - Chave única para o cache do react-query
 */
const CategoryArtistList = ({ 
  limit = 20, 
  searchTerm = '', 
  sortBy = 'popular', 
  categoryId = null,
  queryKey = 'categoryArtists'
}) => {
  const [page, setPage] = useState(1);
  const [allArtists, setAllArtists] = useState([]);
  const [hasMoreArtists, setHasMoreArtists] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  // Resetar a página e os artistas quando mudar os filtros
  useEffect(() => {
    setPage(1);
    setAllArtists([]);
    setHasMoreArtists(true);
  }, [searchTerm, sortBy, categoryId]);

  // Busca artistas da API
  const { data: newArtists, isLoading: loadingArtists } = useQuery(
    [queryKey, searchTerm, sortBy, categoryId, page],
    async () => {
      console.log(`Buscando artistas: página ${page}, limite ${limit}`);
      const response = await api.get('/artists/', {
        params: {
          limit: limit,
          skip: (page - 1) * limit,
          search: searchTerm || undefined,
          sort_by: sortBy || undefined,
          category_id: categoryId || undefined
        }
      });
      console.log(`Artistas recebidos: ${response.data.length}`);
      return response.data;
    },
    {
      staleTime: 1000 * 60, // 1 minuto
      enabled: true,
      retry: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: false, // Não manter dados anteriores para evitar problemas com o estado
      onError: (error) => {
        console.error('Erro ao carregar artistas:', error);
        setLoadingMore(false); // Garantir que o estado de loading é resetado em caso de erro
      }
    }
  );

  // Atualiza o array de artistas quando novos dados são carregados
  useEffect(() => {
    if (newArtists) {
      // Verifica se há mais artistas para carregar
      if (newArtists.length < limit) {
        setHasMoreArtists(false);
      }
      
      // Adiciona novos artistas ao array existente, evitando duplicatas
      setAllArtists(prevArtists => {
        if (page === 1) {
          // Se for a primeira página, substitui completamente
          return [...newArtists];
        } else {
          // Se não for a primeira página, adiciona evitando duplicatas
          const artistIds = new Set(prevArtists.map(artist => artist.id));
          const uniqueNewArtists = newArtists.filter(artist => !artistIds.has(artist.id));
          return [...prevArtists, ...uniqueNewArtists];
        }
      });
    }
  }, [newArtists, limit, page]);

  // Função para carregar mais itens
  const loadMore = useCallback(() => {
    console.log('Carregando mais artistas...');
    setLoadingMore(true);
    setPage(prevPage => prevPage + 1);
  }, []);

  // Atualiza o estado de loadingMore quando o carregamento termina
  useEffect(() => {
    if (!loadingArtists) {
      setLoadingMore(false);
    }
  }, [loadingArtists]);

  if (loadingArtists && page === 1) {
    return (
      <div className="flex justify-center items-center h-40">
        <LoadingSpinner size="md" />
      </div>
    );
  }

  if (!allArtists || allArtists.length === 0) {
    if (loadingArtists) {
      return (
        <div className="flex justify-center items-center h-40">
          <LoadingSpinner size="md" />
        </div>
      );
    }
    
    return (
      <div className="bg-gray-100 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 text-gray-700 dark:text-gray-300 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline">Nenhum artista encontrado.</span>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {allArtists.map((artist, index) => (
          <ArtistCard key={artist.id} artist={artist} />
        ))}
      </div>
      
      {loadingArtists && page > 1 && (
        <div className="flex justify-center mt-4">
          <LoadingSpinner size="sm" />
        </div>
      )}
      
      {hasMoreArtists && (
        <div className="mt-4 text-center">
          <button
            onClick={loadMore}
            disabled={loadingArtists || loadingMore}
            className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 disabled:opacity-70 flex items-center justify-center mx-auto"
          >
            {loadingArtists || loadingMore ? (
              <>
                <LoadingSpinner size="sm" color="white" className="mr-2" />
                <span>Carregando...</span>
              </>
            ) : (
              'Carregar mais'
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default CategoryArtistList;