import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Componente de página genérica para ser usado como placeholder
 * até que as páginas reais sejam implementadas.
 */
const GenericPage = ({ title, description }) => {
  const location = useLocation();
  const pageName = location.pathname.split('/').pop() || 'home';
  const pageTitle = title || `Página ${pageName.charAt(0).toUpperCase() + pageName.slice(1)}`;
  const pageDescription = description || `Esta é a página ${pageName}. Conteúdo em desenvolvimento.`;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-8 max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-4">
          {pageTitle}
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
          {pageDescription}
        </p>
        <div className="p-6 bg-gray-100 dark:bg-dark-700 rounded-lg">
          <p className="text-gray-500 dark:text-gray-400 italic">
            Esta página está em desenvolvimento e será implementada em breve.
          </p>
          <p className="text-gray-500 dark:text-gray-400 mt-2">
            Caminho atual: <code className="bg-gray-200 dark:bg-dark-600 px-2 py-1 rounded">{location.pathname}</code>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GenericPage;