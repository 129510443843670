import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '../../services/api';
import SongCard from './SongCard';
import LoadingSpinner from '../common/LoadingSpinner';

/**
 * Componente otimizado para exibir uma lista de músicas com botão de carregar mais
 * @param {number} limit - Número máximo de músicas a serem exibidas por página
 * @param {string} searchTerm - Termo de busca para filtrar músicas
 * @param {string|number} categoryId - ID da categoria para filtrar músicas
 * @param {string} sortBy - Campo para ordenação (views, date, title)
 * @param {string} queryKey - Chave única para o cache do react-query
 */
const CategorySongList = ({ 
  limit = 20, 
  searchTerm = '', 
  categoryId = null, 
  sortBy = 'date',
  queryKey = 'categorySongs'
}) => {
  const [page, setPage] = useState(1);
  const [allSongs, setAllSongs] = useState([]);
  const [hasMoreSongs, setHasMoreSongs] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  // Resetar a página e as músicas quando mudar os filtros
  useEffect(() => {
    setPage(1);
    setAllSongs([]);
    setHasMoreSongs(true);
  }, [searchTerm, categoryId, sortBy]);

  // Busca músicas da API
  const { data: newSongs, isLoading: loadingSongs } = useQuery(
    [queryKey, searchTerm, categoryId, sortBy, page],
    async () => {
      console.log(`Buscando músicas: página ${page}, limite ${limit}`);
      const response = await api.get('/songs/', {
        params: {
          limit: limit,
          skip: (page - 1) * limit,
          search: searchTerm || undefined,
          category_id: categoryId || undefined,
          sort_by: sortBy || undefined
        }
      });
      console.log(`Músicas recebidas: ${response.data.length}`);
      return response.data;
    },
    {
      staleTime: 1000 * 60, // 1 minuto
      enabled: true,
      retry: 1,
      refetchOnWindowFocus: false,
      keepPreviousData: false, // Não manter dados anteriores para evitar problemas com o estado
      onError: (error) => {
        console.error('Erro ao carregar músicas:', error);
        setLoadingMore(false); // Garantir que o estado de loading é resetado em caso de erro
      }
    }
  );

  // Atualiza o array de músicas quando novos dados são carregados
  useEffect(() => {
    if (newSongs) {
      // Verifica se há mais músicas para carregar
      if (newSongs.length < limit) {
        setHasMoreSongs(false);
      }
      
      // Adiciona novas músicas ao array existente, evitando duplicatas
      setAllSongs(prevSongs => {
        if (page === 1) {
          // Se for a primeira página, substitui completamente
          return [...newSongs];
        } else {
          // Se não for a primeira página, adiciona evitando duplicatas
          const songIds = new Set(prevSongs.map(song => song.id));
          const uniqueNewSongs = newSongs.filter(song => !songIds.has(song.id));
          return [...prevSongs, ...uniqueNewSongs];
        }
      });
    }
  }, [newSongs, limit, page]);

  // Função para carregar mais itens
  const loadMore = useCallback(() => {
    console.log('Carregando mais músicas...');
    setLoadingMore(true);
    setPage(prevPage => prevPage + 1);
  }, []);

  // Atualiza o estado de loadingMore quando o carregamento termina
  useEffect(() => {
    if (!loadingSongs) {
      setLoadingMore(false);
    }
  }, [loadingSongs]);

  if (loadingSongs && page === 1) {
    return (
      <div className="flex justify-center items-center h-40">
        <LoadingSpinner size="md" />
      </div>
    );
  }

  if (!allSongs || allSongs.length === 0) {
    if (loadingSongs) {
      return (
        <div className="flex justify-center items-center h-40">
          <LoadingSpinner size="md" />
        </div>
      );
    }
    
    return (
      <div className="bg-gray-100 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 text-gray-700 dark:text-gray-300 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline">
          {searchTerm || categoryId || sortBy
            ? "Nenhuma música encontrada com os filtros aplicados."
            : "Nenhuma música encontrada."}
        </span>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {allSongs.map((song) => (
          <SongCard key={song.id} song={song} />
        ))}
      </div>
      
      {loadingSongs && page > 1 && (
        <div className="flex justify-center mt-4">
          <LoadingSpinner size="sm" />
        </div>
      )}
      
      {hasMoreSongs && (
        <div className="mt-4 text-center">
          <button
            onClick={loadMore}
            disabled={loadingSongs || loadingMore}
            className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 disabled:opacity-70 flex items-center justify-center mx-auto"
          >
            {loadingSongs || loadingMore ? (
              <>
                <LoadingSpinner size="sm" color="white" className="mr-2" />
                <span>Carregando...</span>
              </>
            ) : (
              'Carregar mais'
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default CategorySongList;