import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTheme } from './context/ThemeContext';
import { useAuth } from './context/AuthContext';
import { PlayerProvider } from './context/PlayerContext';
import MusicPlayer from './components/player/MusicPlayer';

// Layouts
import MainLayout from './components/layouts/MainLayout';
import AuthLayout from './components/layouts/AuthLayout';

// Importando todas as páginas do arquivo index.js
import {
  HomePage,
  AlbumsPage,
  AlbumDetailPage,
  SongsPage,
  SongDetailPage,
  ArtistsPage,
  ArtistDetailPage,
  SearchPage,
  AboutPage,
  ContactPage,
  NotFoundPage,
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  DashboardPage,
  ProfilePage,
  MyAlbumsPage,
  MySongsPage,
  UploadAlbumPage,
  UploadSongPage,
  PlaylistsPage,
  PlaylistDetailPage,
  SettingsPage,
  CategoryDetailPage,
  StatsPage,
  FollowersPage
} from './pages';

// Componentes
import ProtectedRoute from './components/auth/ProtectedRoute';
import ScrollToTop from './components/common/ScrollToTop';

function App() {
  const { theme } = useTheme();
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  // Aplica a classe do tema ao elemento HTML
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  // Rola para o topo quando a rota muda
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  return (
    <PlayerProvider>
      <ScrollToTop />
      <Routes>
        {/* Rotas públicas com layout principal */}
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="albums" element={<AlbumsPage />} />
          <Route path="albums/:id" element={<AlbumDetailPage />} />
          <Route path="category/:id" element={<CategoryDetailPage />} />
          <Route path="songs" element={<SongsPage />} />
          <Route path="songs/:id" element={<SongDetailPage />} />
          <Route path="artists" element={<ArtistsPage />} />
          <Route path="artists/:id" element={<ArtistDetailPage />} />
          <Route path=":slug" element={<ArtistDetailPage />} />
          <Route path="search" element={<SearchPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        {/* Rotas de autenticação */}
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
        </Route>

        {/* Rotas protegidas */}
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        }>
          <Route index element={<DashboardPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="my-albums" element={<MyAlbumsPage />} />
          <Route path="my-songs" element={<MySongsPage />} />
          <Route path="stats" element={<StatsPage />} />
          <Route path="followers" element={<FollowersPage />} />
          <Route path="upload-album" element={<UploadAlbumPage />} />
          <Route path="upload-song" element={<UploadSongPage />} />
          <Route path="playlists" element={<PlaylistsPage />} />
          <Route path="playlists/:id" element={<PlaylistDetailPage />} />
          <Route path="settings" element={<SettingsPage />} />
        </Route>
      </Routes>

      {/* Player de música global */}
      <MusicPlayer />
    </PlayerProvider>
  );
}

export default App;