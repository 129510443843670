import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

// Cria o contexto de autenticação
const AuthContext = createContext();

// Hook personalizado para usar o contexto de autenticação
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provedor do contexto de autenticação
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Efeito para verificar se o usuário está autenticado ao carregar a página
  useEffect(() => {
    const checkAuth = async () => {
      if (token) {
        try {
          // Configura o token no cabeçalho de autorização
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          
          // Busca os dados do usuário atual
          const response = await api.get('/users/me');
          setCurrentUser(response.data);
        } catch (error) {
          console.error('Erro ao verificar autenticação:', error);
          // Se houver erro, remove o token e os dados do usuário
          logout();
        }
      }
      setLoading(false);
    };

    checkAuth();
  }, [token]);

  // Função para fazer login
  const login = async (email, password) => {
    try {
      // Faz a requisição de login
      const response = await api.post('/auth/token', new URLSearchParams({
        username: email,
        password: password
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      // Obtém o token de acesso
      const accessToken = response.data.access_token;
      
      // Armazena o token no localStorage
      localStorage.setItem('token', accessToken);
      
      // Configura o token no cabeçalho de autorização
      api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      
      // Busca os dados do usuário atual
      const userResponse = await api.get('/users/me');
      
      // Atualiza o estado
      setToken(accessToken);
      setCurrentUser(userResponse.data);
      
      return true;
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      return false;
    }
  };

  // Função para fazer registro
  const register = async (userData) => {
    try {
      // Faz a requisição de registro
      await api.post('/users/', userData);
      
      // Faz login automaticamente após o registro
      return await login(userData.email, userData.password);
    } catch (error) {
      console.error('Erro ao registrar:', error);
      return false;
    }
  };

  // Função para fazer logout
  const logout = () => {
    // Remove o token do localStorage
    localStorage.removeItem('token');
    
    // Remove o token do cabeçalho de autorização
    delete api.defaults.headers.common['Authorization'];
    
    // Limpa o estado
    setToken(null);
    setCurrentUser(null);
    
    // Redireciona para a página inicial
    navigate('/');
  };

  // Função para atualizar o perfil do usuário
  const updateProfile = async (userData) => {
    try {
      const response = await api.patch('/users/me', userData);
      setCurrentUser(response.data);
      return true;
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      return false;
    }
  };

  // Função para verificar se o usuário está autenticado
  const isAuthenticated = !!token && !!currentUser;

  // Valor do contexto
  const value = {
    currentUser,
    login,
    register,
    logout,
    updateProfile,
    isAuthenticated,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};