import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { usePlayer } from '../../context/PlayerContext';

/**
 * Componente para exibir os detalhes de uma música
 */
const SongDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { playSong, addToQueue, currentTrack, transformSongUrl } = usePlayer();
  const [song, setSong] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  // Busca os detalhes da música da API usando o endpoint específico /songs/{id}
  useEffect(() => {
    const fetchSongDetails = async () => {
      try {
        setIsLoading(true);
        console.log(`Buscando detalhes da música com ID: ${id} (tentativa ${retryCount + 1})`);
        // Usa o endpoint específico
        const response = await api.get(`/songs/${id}`);
        console.log('Resposta da API:', response.data);
        
        setSong(response.data);
        setIsLoading(false);
      } catch (err) {
        console.error('Erro ao buscar detalhes da música:', err);
        setError(err);
        setIsLoading(false);
        
        // Se ainda não atingimos o número máximo de tentativas, tenta novamente
        if (retryCount < maxRetries) {
          console.log(`Tentando novamente em 1 segundo (${retryCount + 1}/${maxRetries})...`);
          setTimeout(() => {
            setRetryCount(retryCount + 1);
          }, 1000);
        } else {
          console.error('Número máximo de tentativas atingido.');
          
          // Redireciona para a página de músicas após 3 segundos
          setTimeout(() => {
            navigate('/songs');
          }, 3000);
        }
      }
    };

    if (id) {
      fetchSongDetails();
    }
  }, [id, navigate, retryCount]);

  // Função para reproduzir a música
  const handlePlaySong = () => {
    if (song) {
      // Usa os metadados pré-processados se disponíveis, caso contrário cria manualmente
      const formattedSong = {
        id: song.id,
        title: song.title,
        file_path: song.file_path,
        artist: song.artist?.username || "Artista Desconhecido",
        album: song.albums && song.albums.length > 0 ? song.albums[0].title : "Álbum Desconhecido",
        image_url: song.albums && song.albums.length > 0 && song.albums[0].cover_image ? song.albums[0].cover_image : null,
        plays: song.plays || 0,
        downloads: song.downloads || 0,
        // Adiciona os metadados pré-processados do backend
        metadata: song.metadata || {
          title: song.title,
          artist: song.artist?.username || "Artista Desconhecido",
          album: song.albums && song.albums.length > 0 ? song.albums[0].title : "Álbum Desconhecido",
          artwork: song.albums && song.albums.length > 0 && song.albums[0].cover_image ? song.albums[0].cover_image : null,
          duration: 0
        }
      };
      
      playSong(formattedSong);
    }
  };

  // Função para adicionar a música à fila
  const handleAddToQueue = () => {
    if (song) {
      // Usa os metadados pré-processados se disponíveis, caso contrário cria manualmente
      const formattedSong = {
        id: song.id,
        title: song.title,
        file_path: song.file_path,
        artist: song.artist?.username || "Artista Desconhecido",
        album: song.albums && song.albums.length > 0 ? song.albums[0].title : "Álbum Desconhecido",
        image_url: song.albums && song.albums.length > 0 && song.albums[0].cover_image ? song.albums[0].cover_image : null,
        plays: song.plays || 0,
        downloads: song.downloads || 0,
        // Adiciona os metadados pré-processados do backend
        metadata: song.metadata || {
          title: song.title,
          artist: song.artist?.username || "Artista Desconhecido",
          album: song.albums && song.albums.length > 0 ? song.albums[0].title : "Álbum Desconhecido",
          artwork: song.albums && song.albums.length > 0 && song.albums[0].cover_image ? song.albums[0].cover_image : null,
          duration: 0
        }
      };
      
      addToQueue(formattedSong);
    }
  };

  // Estado para controlar o download
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  // Função para baixar a música
  const downloadSong = () => {
    if (song && song.file_path) {
      console.log(`Baixando música: ${song.title}`);
      
      // Inicia o processo de download
      setIsDownloading(true);
      setDownloadProgress(0);
      
      // Cria um elemento de áudio temporário para monitorar o progresso de download
      const tempAudio = new Audio();
      tempAudio.src = transformSongUrl(song.file_path);
      
      // Monitora o progresso de download
      tempAudio.addEventListener('progress', (e) => {
        if (tempAudio.buffered.length > 0) {
          const bufferedEnd = tempAudio.buffered.end(tempAudio.buffered.length - 1);
          const duration = tempAudio.duration;
          if (duration > 0) {
            const progress = (bufferedEnd / duration) * 100;
            setDownloadProgress(progress);
            
            // Quando o download estiver completo
            if (progress >= 100) {
              setTimeout(() => {
                setIsDownloading(false);
                // Abre em uma nova aba para download
                window.open(transformSongUrl(song.file_path), '_blank');
              }, 500);
            }
          }
        }
      });
      
      // Em caso de erro
      tempAudio.addEventListener('error', () => {
        console.error('Erro ao baixar a música');
        setIsDownloading(false);
        // Tenta abrir diretamente mesmo com erro
        window.open(transformSongUrl(song.file_path), '_blank');
      });
      
      // Se demorar muito, abre diretamente
      setTimeout(() => {
        if (isDownloading) {
          setIsDownloading(false);
          window.open(transformSongUrl(song.file_path), '_blank');
        }
      }, 10000); // 10 segundos de timeout
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 dark:bg-red-900 border border-red-400 text-red-700 dark:text-red-200 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Erro!</strong>
        <span className="block sm:inline"> Não foi possível carregar os detalhes da música.</span>
      </div>
    );
  }

  if (!song && !isLoading) {
    return (
      <div className="bg-gray-100 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 text-gray-700 dark:text-gray-300 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline">Música não encontrada. Redirecionando para a página de músicas...</span>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-dark-800 rounded-lg shadow-lg overflow-hidden">
      {/* Cabeçalho da música */}
      <div className="relative">
        {/* Banner com gradiente */}
        <div className="h-48 bg-gradient-to-r from-primary-600 to-secondary-600"></div>
        
        {/* Informações principais */}
        <div className="px-6 py-4 md:flex">
          {/* Imagem do álbum ou do DJ/artista */}
          <div className="md:w-64 md:-mt-24 mb-4 md:mb-0 flex-shrink-0">
            {song && song.albums && song.albums.length > 0 && song.albums[0].cover_image ? (
              <img
                src={song.albums[0].cover_image || '/placeholder-album.jpg'}
                alt={`Capa do álbum ${song.albums[0].title}`}
                className="w-full h-64 object-cover rounded-lg shadow-lg"
              />
            ) : song && song.artist && song.artist.profile_image ? (
              <img
                src={song.artist.profile_image.startsWith('http') ? song.artist.profile_image : `https://imagens.baladag4.com.br/crop?width=200&height=200&&file=${song.artist.profile_image.replace('uploads/', '')}`}
                alt={`Imagem do DJ ${song.artist.username}`}
                className="w-full h-64 object-cover rounded-lg shadow-lg"
              />
            ) : (
              <div className="w-full h-64 bg-gray-300 dark:bg-dark-600 rounded-lg shadow-lg flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 text-gray-400 dark:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                </svg>
              </div>
            )}
          </div>
          
          {/* Detalhes da música */}
          {song && (
            <div className="md:ml-6 md:mt-0">
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white">{song.title}</h1>
              <div className="flex items-center mt-2">
                <Link
                  to={song.artist?.url_slug ? `/${song.artist.url_slug}` : `/artists/${song.artist?.id || 0}`}
                  className="text-primary-600 dark:text-primary-400 hover:underline"
                >
                  {song.artist?.username || "Artista Desconhecido"}
                </Link>
                <span className="mx-2 text-gray-500 dark:text-gray-400">•</span>
                <Link to={`/songs?category=${song.category?.id || 0}`} className="text-gray-600 dark:text-gray-300 hover:underline">
                  {song.category?.name || "Categoria Desconhecida"}
                </Link>
              </div>
              
              <div className="flex items-center mt-2 text-sm text-gray-500 dark:text-gray-400">
                <span>{song.plays || 0} reproduções</span>
                <span className="mx-2">•</span>
                <span>{song.downloads || 0} downloads</span>
                <span className="mx-2">•</span>
                <span>{song.created_at ? new Date(song.created_at).getFullYear() : "Ano Desconhecido"}</span>
              </div>
              
              {/* Botões de ação */}
              <div className="flex mt-4 space-x-3">
                <button
                  onClick={handlePlaySong}
                  className="px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white rounded-md"
                >
                  Reproduzir
                </button>
                <button
                  onClick={handleAddToQueue}
                  className="px-4 py-2 bg-secondary-600 hover:bg-secondary-700 text-white rounded-md"
                >
                  Adicionar à fila
                </button>
                <button
                  onClick={downloadSong}
                  disabled={isDownloading}
                  className={`px-4 py-2 relative overflow-hidden ${
                    isDownloading
                      ? 'bg-gray-400 dark:bg-dark-500 cursor-wait'
                      : 'bg-gray-200 dark:bg-dark-600 hover:bg-gray-300 dark:hover:bg-dark-500'
                  } text-gray-800 dark:text-white rounded-md`}
                >
                  {isDownloading ? (
                    <>
                      <div
                        className="absolute top-0 left-0 h-full bg-primary-500 opacity-30"
                        style={{ width: `${downloadProgress}%` }}
                      ></div>
                      <span className="relative z-10">Baixando ({Math.round(downloadProgress)}%)</span>
                    </>
                  ) : (
                    'Baixar'
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Letra da música (se disponível) */}
      {song && song.lyrics && (
        <div className="px-6 py-4 border-t border-gray-200 dark:border-dark-600">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">Letra</h2>
          <div className="bg-gray-50 dark:bg-dark-700 p-4 rounded-md">
            <p className="text-gray-600 dark:text-gray-300 whitespace-pre-line">{song.lyrics}</p>
          </div>
        </div>
      )}
      
      {/* Álbuns relacionados (se disponíveis) */}
      {song && song.albums && song.albums.length > 0 && (
        <div className="px-6 py-4 border-t border-gray-200 dark:border-dark-600">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">Álbuns</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {song.albums.map((album) => (
              <Link 
                key={album.id} 
                to={`/albums/${album.id}`}
                className="bg-gray-50 dark:bg-dark-700 p-3 rounded-md hover:bg-gray-100 dark:hover:bg-dark-600 transition-colors"
              >
                <div className="flex items-center">
                  <div className="w-12 h-12 mr-3">
                    <img 
                      src={album.cover_image || '/placeholder-album.jpg'} 
                      alt={album.title}
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-800 dark:text-white">{album.title}</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{album.artist_name}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
      
      {/* Informações adicionais */}
      {song && (
        <div className="px-6 py-4 border-t border-gray-200 dark:border-dark-600 bg-gray-50 dark:bg-dark-700">
          <div className="flex flex-wrap justify-between text-sm text-gray-500 dark:text-gray-400">
            <div>
              Adicionado em: {song.created_at ? new Date(song.created_at).toLocaleDateString('pt-BR') : "Data desconhecida"}
            </div>
            <div>ID: {song.id}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SongDetail;