import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';

const StatsPage = () => {
  const { currentUser } = useAuth();
  const [stats, setStats] = useState({
    general: {
      totalViews: 0,
      followers: 0,
      following: 0,
      totalLikes: 0,
      totalComments: 0,
      totalShares: 0,
    },
    albums: {
      total: 0,
      approved: 0,
      pending: 0,
      mostViewed: [],
    },
    songs: {
      total: 0,
      approved: 0,
      pending: 0,
      mostPlayed: [],
    },
    timeline: {
      views: [],
      followers: [],
    }
  });
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('month'); // 'week', 'month', 'year', 'all'
  const [debugInfo, setDebugInfo] = useState({});

  useEffect(() => {
    const fetchStats = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        // Usa o endpoint /users/me com o parâmetro include=stats
        const response = await api.get(`/users/me?include=stats`);
        console.log('Dados recebidos da API:', response.data);
        
        if (response.data?.stats) {
          // Formata os dados recebidos para o formato esperado pelo componente
          const monthlyViews = response.data.stats.monthly_views || [];
          const monthlyFollowers = response.data.stats.monthly_followers || [];
          
          console.log('Visualizações mensais:', monthlyViews);
          console.log('Seguidores mensais:', monthlyFollowers);
          
          // Processar os dados
          const processedViews = monthlyViews.map(item => ({
            month: item.month,
            value: parseInt(item.views) || 0
          }));
          
          const processedFollowers = monthlyFollowers.map(item => ({
            month: item.month,
            value: parseInt(item.followers) || 0
          }));
          
          console.log('Visualizações processadas:', processedViews);
          console.log('Seguidores processados:', processedFollowers);
          
          const statsData = {
            general: {
              totalViews: response.data.views || 0,
              followers: response.data.follower_count || 0,
              following: response.data.stats.following_count || 0,
              totalLikes: 0,
              totalComments: 0,
              totalShares: 0,
            },
            albums: {
              total: response.data.album_count || 0,
              approved: response.data.album_count || 0,
              pending: 0,
              mostViewed: response.data.stats.top_albums || [],
            },
            songs: {
              total: response.data.song_count || 0,
              approved: response.data.song_count || 0,
              pending: 0,
              mostPlayed: response.data.stats.top_songs || [],
            },
            timeline: {
              views: processedViews,
              followers: processedFollowers,
            }
          };
          
          setStats(statsData);
          
          // Salvar informações de depuração
          setDebugInfo({
            rawViews: monthlyViews,
            rawFollowers: monthlyFollowers,
            processedViews,
            processedFollowers,
            maxViewsValue: getMaxValue(processedViews),
            maxFollowersValue: getMaxValue(processedFollowers)
          });
        } else {
          // Dados vazios
          setStats({
            general: {
              totalViews: currentUser.views || 0,
              followers: currentUser.follower_count || 0,
              following: 0,
              totalLikes: 0,
              totalComments: 0,
              totalShares: 0,
            },
            albums: {
              total: currentUser.album_count || 0,
              approved: currentUser.album_count || 0,
              pending: 0,
              mostViewed: [],
            },
            songs: {
              total: currentUser.song_count || 0,
              approved: currentUser.song_count || 0,
              pending: 0,
              mostPlayed: [],
            },
            timeline: {
              views: [],
              followers: [],
            }
          });
        }
      } catch (error) {
        console.error('Erro ao buscar estatísticas:', error);
        // Dados vazios em caso de erro
        setStats({
          general: {
            totalViews: currentUser.views || 0,
            followers: currentUser.follower_count || 0,
            following: 0,
            totalLikes: 0,
            totalComments: 0,
            totalShares: 0,
          },
          albums: {
            total: currentUser.album_count || 0,
            approved: currentUser.album_count || 0,
            pending: 0,
            mostViewed: [],
          },
          songs: {
            total: currentUser.song_count || 0,
            approved: currentUser.song_count || 0,
            pending: 0,
            mostPlayed: [],
          },
          timeline: {
            views: [],
            followers: [],
          }
        });
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [currentUser, timeRange]);

  // Função para formatar números grandes
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
  };

  // Encontrar o valor máximo para os gráficos
  const getMaxValue = (data) => {
    if (!data || data.length === 0) return 1;
    const values = data.map(item => Number(item.value) || 0);
    const max = Math.max(...values);
    console.log('Valores:', values, 'Máximo:', max);
    return max > 0 ? max : 1;
  };

  // Calcular a altura da barra em pixels (não em porcentagem)
  const calculateBarHeightInPixels = (value, maxValue, maxHeightInPixels = 200) => {
    if (maxValue <= 0) return 1;
    // Calcular a altura proporcional em pixels
    const heightInPixels = (value / maxValue) * maxHeightInPixels;
    // Garantir uma altura mínima de 1 pixel
    return Math.max(Math.round(heightInPixels), 1);
  };

  const maxViewsValue = getMaxValue(stats.timeline.views);
  const maxFollowersValue = getMaxValue(stats.timeline.followers);

  console.log('Valor máximo para visualizações:', maxViewsValue);
  console.log('Valor máximo para seguidores:', maxFollowersValue);
  console.log('Informações de depuração:', debugInfo);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-400 mb-2">
          Estatísticas
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Acompanhe o desempenho do seu perfil, álbuns e músicas.
        </p>
      </div>


      {/* Cards de estatísticas gerais */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-blue-100 dark:bg-blue-900">
              <svg className="w-6 h-6 text-blue-500 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path>
              </svg>
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Visualizações</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 dark:text-white">{formatNumber(stats.general.totalViews)}</p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-green-100 dark:bg-green-900">
              <svg className="w-6 h-6 text-green-500 dark:text-green-300" fill="currentColor" viewBox="0 0 20 20">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
              </svg>
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Seguidores</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 dark:text-white">{formatNumber(stats.general.followers)}</p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-purple-100 dark:bg-purple-900">
              <svg className="w-6 h-6 text-purple-500 dark:text-purple-300" fill="currentColor" viewBox="0 0 20 20">
                <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z"></path>
              </svg>
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Músicas</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 dark:text-white">{stats.songs.total}</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {stats.songs.approved} aprovadas, {stats.songs.pending} pendentes
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Gráficos */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        {/* Gráfico de visualizações */}
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">Visualizações ao longo do tempo</h2>
          {stats.timeline.views && stats.timeline.views.length > 0 ? (
            <div className="relative h-64">
              {/* Eixo Y (escala) */}
              <div className="absolute left-0 top-0 bottom-0 w-10 flex flex-col justify-between text-xs text-gray-500">
                <div>{maxViewsValue}</div>
                <div>{Math.round(maxViewsValue * 0.75)}</div>
                <div>{Math.round(maxViewsValue * 0.5)}</div>
                <div>{Math.round(maxViewsValue * 0.25)}</div>
                <div>0</div>
              </div>
              
              {/* Linhas de grade */}
              <div className="absolute left-10 right-0 top-0 bottom-0">
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
              </div>
              
              {/* Barras do gráfico */}
              <div className="absolute left-10 right-0 bottom-0 flex items-end space-x-2 h-56">
                {stats.timeline.views.map((item, index) => {
                  const heightInPixels = calculateBarHeightInPixels(item.value, maxViewsValue, 224); // 224px = 56 * 4 (h-56)
                  console.log(`Barra ${index}: valor=${item.value}, altura=${heightInPixels}px`);
                  
                  return (
                    <div key={index} className="flex flex-col items-center flex-1">
                      <div className="text-xs font-semibold text-blue-700 dark:text-blue-300 mb-1">
                        {item.value}
                      </div>
                      <div
                        className="w-full bg-blue-500 dark:bg-blue-600 rounded-t border border-blue-600 dark:border-blue-700"
                        style={{ height: `${heightInPixels}px` }}
                      ></div>
                      <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 transform -rotate-45 origin-top-left">
                        {item.month}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="h-64 flex items-center justify-center">
              <p className="text-gray-500 dark:text-gray-400">Não há dados de visualizações disponíveis.</p>
            </div>
          )}
        </div>

        {/* Gráfico de seguidores */}
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">Novos seguidores</h2>
          {stats.timeline.followers && stats.timeline.followers.length > 0 ? (
            <div className="relative h-64">
              {/* Eixo Y (escala) */}
              <div className="absolute left-0 top-0 bottom-0 w-10 flex flex-col justify-between text-xs text-gray-500">
                <div>{maxFollowersValue}</div>
                <div>{Math.round(maxFollowersValue * 0.75)}</div>
                <div>{Math.round(maxFollowersValue * 0.5)}</div>
                <div>{Math.round(maxFollowersValue * 0.25)}</div>
                <div>0</div>
              </div>
              
              {/* Linhas de grade */}
              <div className="absolute left-10 right-0 top-0 bottom-0">
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
                <div className="border-b border-gray-200 dark:border-gray-700 h-1/4"></div>
              </div>
              
              {/* Barras do gráfico */}
              <div className="absolute left-10 right-0 bottom-0 flex items-end space-x-2 h-56">
                {stats.timeline.followers.map((item, index) => {
                  const heightInPixels = calculateBarHeightInPixels(item.value, maxFollowersValue, 224); // 224px = 56 * 4 (h-56)
                  console.log(`Barra ${index}: valor=${item.value}, altura=${heightInPixels}px`);
                  
                  return (
                    <div key={index} className="flex flex-col items-center flex-1">
                      <div className="text-xs font-semibold text-green-700 dark:text-green-300 mb-1">
                        {item.value}
                      </div>
                      <div
                        className="w-full bg-green-500 dark:bg-green-600 rounded-t border border-green-600 dark:border-green-700"
                        style={{ height: `${heightInPixels}px` }}
                      ></div>
                      <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 transform -rotate-45 origin-top-left">
                        {item.month}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="h-64 flex items-center justify-center">
              <p className="text-gray-500 dark:text-gray-400">Não há dados de novos seguidores disponíveis.</p>
            </div>
          )}
        </div>
      </div>

      {/* Músicas mais populares */}
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">Músicas mais populares</h2>
        {stats.songs.mostPlayed && stats.songs.mostPlayed.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-600">
              <thead className="bg-gray-50 dark:bg-dark-700">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Música
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Álbum
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Visualizações
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-dark-800 divide-y divide-gray-200 dark:divide-dark-600">
                {stats.songs.mostPlayed.map((song, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 dark:text-white">{song.title}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-white">{formatNumber(song.views)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-gray-500 dark:text-gray-400">Nenhuma música encontrada.</p>
        )}
      </div>

      {/* Álbuns mais populares */}
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">Álbuns mais populares</h2>
        {stats.albums.mostViewed && stats.albums.mostViewed.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-600">
              <thead className="bg-gray-50 dark:bg-dark-700">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Ano
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Visualizações
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-dark-800 divide-y divide-gray-200 dark:divide-dark-600">
                {stats.albums.mostViewed.map((album, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 dark:text-white">{album.title}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 dark:text-gray-400">{album.year}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-white">{formatNumber(album.views)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-gray-500 dark:text-gray-400">Nenhum álbum encontrado.</p>
        )}
      </div>
      
    </div>
  );
};

export default StatsPage;