import React from 'react';

/**
 * Componente de spinner de carregamento
 * @param {string} size - Tamanho do spinner (sm, md, lg)
 * @param {string} color - Cor do spinner (primary, secondary, white)
 */
const LoadingSpinner = ({ size = 'md', color = 'primary' }) => {
  // Mapeia tamanhos para classes CSS
  const sizeClasses = {
    sm: 'h-4 w-4',
    md: 'h-8 w-8',
    lg: 'h-12 w-12',
  };

  // Mapeia cores para classes CSS
  const colorClasses = {
    primary: 'border-primary-600 border-t-transparent',
    secondary: 'border-secondary-600 border-t-transparent',
    white: 'border-white border-t-transparent',
  };

  // Seleciona as classes com base nos props
  const spinnerSize = sizeClasses[size] || sizeClasses.md;
  const spinnerColor = colorClasses[color] || colorClasses.primary;

  return (
    <div className="flex items-center justify-center">
      <div
        className={`animate-spin rounded-full border-2 ${spinnerSize} ${spinnerColor}`}
        role="status"
        aria-label="Carregando"
      >
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;