import React from 'react';
import { useQuery } from 'react-query';
import api from '../../services/api';

/**
 * Componente para exibir o conteúdo da página Sobre
 */
const AboutContent = () => {
  // Busca as informações sobre a plataforma da API
  const { data: aboutInfo, isLoading, error } = useQuery('about', async () => {
    const response = await api.get('/about/');
    return response.data;
  }, {
    staleTime: 1000 * 60 * 60, // 1 hora
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-40">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 dark:bg-red-900 border border-red-400 text-red-700 dark:text-red-200 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Erro!</strong>
        <span className="block sm:inline"> Não foi possível carregar as informações sobre a plataforma.</span>
      </div>
    );
  }

  // Se não houver dados da API, exibe conteúdo estático
  const content = aboutInfo || {
    title: 'Sobre o Balada G4',
    description: 'A maior plataforma de músicas eletrônicas, funk, DJs, MCs e som automotivo do Brasil',
    history: 'O Balada G4 foi fundado em 2010 com o objetivo de promover a música eletrônica e o funk brasileiro, oferecendo um espaço para artistas divulgarem seu trabalho e para os fãs descobrirem novas músicas.',
    mission: 'Nossa missão é conectar artistas e fãs, promovendo a cultura musical brasileira e oferecendo uma plataforma de qualidade para a divulgação de conteúdo musical.',
    team: [
      { name: 'João Silva', role: 'CEO & Fundador' },
      { name: 'Maria Oliveira', role: 'Diretora de Conteúdo' },
      { name: 'Pedro Santos', role: 'Desenvolvedor Chefe' }
    ]
  };

  return (
    <div className="space-y-8">
      <section>
        <h2 className="text-2xl font-bold text-primary-600 dark:text-primary-400 mb-4">Nossa História</h2>
        <p className="text-gray-700 dark:text-gray-300">{content.history}</p>
      </section>

      <section>
        <h2 className="text-2xl font-bold text-primary-600 dark:text-primary-400 mb-4">Nossa Missão</h2>
        <p className="text-gray-700 dark:text-gray-300">{content.mission}</p>
      </section>

      <section>
        <h2 className="text-2xl font-bold text-primary-600 dark:text-primary-400 mb-4">Nossa Equipe</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {content.team.map((member, index) => (
            <div key={index} className="bg-white dark:bg-dark-800 rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-white">{member.name}</h3>
              <p className="text-gray-600 dark:text-gray-400 mt-2">{member.role}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AboutContent;