import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { usePlayer } from '../../context/PlayerContext';

/**
 * Componente para exibir os detalhes de um álbum
 */
const AlbumDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { playSong, addToQueue, playAlbum, currentTrack, transformSongUrl } = usePlayer();
  const [album, setAlbum] = useState(null);
  const [songs, setSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  // Busca os detalhes do álbum da API usando o endpoint específico /albums/{id}
  useEffect(() => {
    const fetchAlbumDetails = async () => {
      try {
        setIsLoading(true);
        console.log(`Buscando detalhes do álbum com ID: ${id} (tentativa ${retryCount + 1})`);
        // Usa o endpoint específico conforme solicitado
        const response = await api.get(`/albums/${id}`);
        console.log('Resposta da API:', response.data);
        
        setAlbum(response.data);
        
        // Extrai as músicas do álbum se existirem
        if (response.data && response.data.songs && Array.isArray(response.data.songs)) {
          console.log('Músicas encontradas:', response.data.songs);
          setSongs(response.data.songs);
        } else {
          console.log('Nenhuma música encontrada no álbum');
          setSongs([]);
        }
        
        setIsLoading(false);
        setIsLoading(false);
      } catch (err) {
        console.error('Erro ao buscar detalhes do álbum:', err);
        setError(err);
        setIsLoading(false);
        
        // Se ainda não atingimos o número máximo de tentativas, tenta novamente
        if (retryCount < maxRetries) {
          console.log(`Tentando novamente em 1 segundo (${retryCount + 1}/${maxRetries})...`);
          setTimeout(() => {
            setRetryCount(retryCount + 1);
          }, 1000);
        } else {
          console.error('Número máximo de tentativas atingido. Usando método alternativo...');
          
          // Método alternativo: buscar da lista de álbuns recentes
          try {
            const alternativeResponse = await api.get('/albums/recent', {
              params: {
                limite: 100,
                enabled: 1
              }
            });
            
            const foundAlbum = alternativeResponse.data.find(
              album => album.idcd === parseInt(id) || album.id === parseInt(id)
            );
            
            if (foundAlbum) {
              console.log('Álbum encontrado via método alternativo:', foundAlbum);
              
              // Formata o álbum para o formato esperado pelo componente
              const formattedAlbum = {
                id: foundAlbum.idcd || foundAlbum.id,
                title: foundAlbum.name || foundAlbum.title,
                description: foundAlbum.description || '',
                cover_image: foundAlbum.poster || foundAlbum.cover_url,
                created_at: foundAlbum.datesent || foundAlbum.release_date,
                downloads: foundAlbum.downloads || 0,
                songs_count: foundAlbum.songs_count || 0,
                artist: {
                  id: foundAlbum.autorid || 0,
                  username: foundAlbum.autor || foundAlbum.artist_name
                },
                category: {
                  id: 0,
                  name: foundAlbum.categoria || 'Música'
                },
                songs: [] // Inicialmente vazio
              };
              
              setAlbum(formattedAlbum);
            } else {
              console.error('Álbum não encontrado em nenhum método');
              // Redireciona para a página de álbuns após 3 segundos
              setTimeout(() => {
                navigate('/albums');
              }, 3000);
            }
          } catch (alternativeErr) {
            console.error('Erro no método alternativo:', alternativeErr);
          }
        }
      }
    };

    if (id) {
      fetchAlbumDetails();
    }
  }, [id, navigate, retryCount]);

  // Função para reproduzir uma música do álbum
  const handlePlaySong = (index) => {
    if (songs && songs[index]) {
      // Verifica se a música já tem metadados, caso contrário, adiciona
      const songToPlay = songs[index].metadata ?
        songs[index] :
        {
          ...songs[index],
          metadata: {
            title: songs[index].title,
            artist: album?.artist?.username || album?.artist_name || "Artista Desconhecido",
            album: album?.title || "Álbum Desconhecido",
            artwork: album?.cover_image || null,
            duration: 0
          }
        };
      
      playSong(songToPlay);
    }
  };

  // Função para adicionar uma música à fila
  const handleAddToQueue = (index) => {
    if (songs && songs[index]) {
      // Verifica se a música já tem metadados, caso contrário, adiciona
      const songToQueue = songs[index].metadata ?
        songs[index] :
        {
          ...songs[index],
          metadata: {
            title: songs[index].title,
            artist: album?.artist?.username || album?.artist_name || "Artista Desconhecido",
            album: album?.title || "Álbum Desconhecido",
            artwork: album?.cover_image || null,
            duration: 0
          }
        };
      
      addToQueue(songToQueue);
    }
  };

  // Função para reproduzir o álbum inteiro
  const handlePlayAlbum = () => {
    if (album && songs && songs.length > 0) {
      // Adiciona metadados a todas as músicas do álbum se não existirem
      const songsWithMetadata = songs.map(song => {
        if (song.metadata) {
          return song;
        }
        return {
          ...song,
          metadata: {
            title: song.title,
            artist: album?.artist?.username || album?.artist_name || "Artista Desconhecido",
            album: album?.title || "Álbum Desconhecido",
            artwork: album?.cover_image || null,
            duration: 0
          }
        };
      });
      
      // Cria um objeto de álbum com as músicas para passar para o player
      const albumWithSongs = {
        ...album,
        songs: songsWithMetadata,
        // Adiciona metadados ao álbum se não existirem
        metadata: album.metadata || {
          title: album.title,
          artist: album?.artist?.username || album?.artist_name || "Artista Desconhecido",
          album: album.title,
          artwork: album.cover_image || null,
          songs_count: songs.length
        }
      };
      
      playAlbum(albumWithSongs);
    }
  };

  // Função para baixar o álbum
  const downloadAlbum = () => {
    // Implementação do download do álbum
    if (album) {
      console.log(`Baixando álbum: ${album.title}`);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 dark:bg-red-900 border border-red-400 text-red-700 dark:text-red-200 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Erro!</strong>
        <span className="block sm:inline"> Não foi possível carregar os detalhes do álbum.</span>
      </div>
    );
  }

  if (!album && !isLoading) {
    return (
      <div className="bg-gray-100 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 text-gray-700 dark:text-gray-300 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline">Álbum não encontrado. Redirecionando para a página de álbuns...</span>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-dark-800 rounded-lg shadow-lg overflow-hidden">
      {/* Cabeçalho do álbum */}
      <div className="relative">
        {/* Banner com gradiente */}
        <div className="h-48 bg-gradient-to-r from-primary-600 to-secondary-600"></div>
        
        {/* Informações principais */}
        <div className="px-6 py-4 md:flex">
          {/* Capa do álbum */}
          <div className="md:w-64 md:-mt-24 mb-4 md:mb-0 flex-shrink-0">
            {album && (
              <img
                src={album.cover_image || '/placeholder-album.jpg'}
                alt={album.title}
                className="w-full h-64 object-cover rounded-lg shadow-lg"
              />
            )}
          </div>
          
          {/* Detalhes do álbum */}
          {album && (
            <div className="md:ml-6 md:mt-0">
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white">{album.title}</h1>
              <div className="flex items-center mt-2">
                <Link
                  to={album.artist?.url_slug ? `/${album.artist.url_slug}` : `/artists/${album.artist?.id || 0}`}
                  className="text-primary-600 dark:text-primary-400 hover:underline"
                >
                  {album.artist?.username || "Artista Desconhecido"}
                </Link>
                <span className="mx-2 text-gray-500 dark:text-gray-400">•</span>
                <Link to={`/albums?category=${album.category?.id || 0}`} className="text-gray-600 dark:text-gray-300 hover:underline">
                  {album.category?.name || "Categoria Desconhecida"}
                </Link>
              </div>
              
              <div className="flex items-center mt-2 text-sm text-gray-500 dark:text-gray-400">
                <span>{album.songs_count || 0} músicas</span>
                <span className="mx-2">•</span>
                <span>{album.created_at ? new Date(album.created_at).getFullYear() : "Ano Desconhecido"}</span>
                <span className="mx-2">•</span>
                <span>{album.downloads || 0} downloads</span>
              </div>
              
              {/* Botões de ação */}
              <div className="flex mt-4 space-x-3">
                <button
                  onClick={handlePlayAlbum}
                  disabled={songs.length === 0}
                  className={`px-4 py-2 rounded-md ${
                    songs.length > 0
                      ? 'bg-primary-600 hover:bg-primary-700 text-white'
                      : 'bg-gray-300 dark:bg-dark-600 text-gray-500 dark:text-gray-400 cursor-not-allowed'
                  }`}
                >
                  Reproduzir
                </button>
                <button
                  onClick={downloadAlbum}
                  className="px-4 py-2 bg-gray-200 dark:bg-dark-600 hover:bg-gray-300 dark:hover:bg-dark-500 text-gray-800 dark:text-white rounded-md"
                >
                  Baixar
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Descrição do álbum */}
      {album && album.description && (
        <div className="px-6 py-4 border-t border-gray-200 dark:border-dark-600">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">Descrição</h2>
          <p className="text-gray-600 dark:text-gray-300 whitespace-pre-line">{album.description}</p>
        </div>
      )}
      
      {/* Lista de músicas */}
      <div className="px-6 py-4 border-t border-gray-200 dark:border-dark-600">
        <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Músicas</h2>
        
        {songs.length === 0 ? (
          <p className="text-gray-500 dark:text-gray-400">
            {isLoading ? "Carregando músicas..." : "Este álbum não possui músicas ou não foi possível carregá-las."}
          </p>
        ) : (
          <div className="divide-y divide-gray-200 dark:divide-dark-600">
            {songs.map((song, index) => (
              <div
                key={song.id || index}
                className={`py-3 px-2 flex items-center hover:bg-gray-100 dark:hover:bg-dark-700 rounded-md ${
                  currentTrack && currentTrack.id === song.id ? 'bg-gray-100 dark:bg-dark-700' : ''
                }`}
              >
                <div className="w-8 text-center text-gray-500 dark:text-gray-400">{index + 1}</div>
                <div className="flex-grow ml-4">
                  <div className="font-medium text-gray-800 dark:text-white">{song.title}</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {song.plays || 0} reproduções
                  </div>
                </div>
                <div className="text-gray-500 dark:text-gray-400 text-sm">
                  {song.duration || '00:00'}
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => handlePlaySong(index)}
                    className="ml-4 p-2 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
                    title="Reproduzir"
                  >
                    {currentTrack && currentTrack.id === song.id ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                      </svg>
                    )}
                  </button>
                  <button
                    onClick={() => handleAddToQueue(index)}
                    className="ml-2 p-2 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
                    title="Adicionar à fila"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                    </svg>
                  </button>
                  <a
                    href={transformSongUrl(song.file_path)}
                    download={song.title}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 p-2 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
                    title="Baixar"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
      {/* Informações adicionais */}
      {album && (
        <div className="px-6 py-4 border-t border-gray-200 dark:border-dark-600 bg-gray-50 dark:bg-dark-700">
          <div className="flex flex-wrap justify-between text-sm text-gray-500 dark:text-gray-400">
            <div>
              Adicionado em: {album.created_at ? new Date(album.created_at).toLocaleDateString('pt-BR') : "Data desconhecida"}
            </div>
            <div>ID: {album.id}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlbumDetail;